import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import BasicDatePicker from './BasicDatePicker';
import { SERVER_URL } from '../constants';
import { formatDateObjectToString, formatDate } from '../helpers/Helpers';
import { Divider, Stack } from '@mui/material';
import QrCode from './QrCode';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AppLinkCopyButton from './AppLinkCopyButton';
import AppLinkCopyCodeButton from './AppLinkCopyCodeButton';
import SocialMediaShare from './SocialMediaShare';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const minSliderValue = 2;
const defaultSliderValue = 10;

function GenerateSchedLink (props) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setMaxAppointments(defaultSliderValue);
    };

    const handleClose = () => {
        setOpen(false);
        setAppLink("");
        setIsClicked(false);
        setDisableDatePicker(false);
        setDisableSlider(false);
        props.fetchOffices();
        props.setRecentAppLinkGenerate(true);
    };

    const onGenerateCodeClick = () => {
        if(!isClicked) setIsClicked(true);
        fetchSchedLink();
    };

    const [date, setDate] = useState(new Date());

    const [disableGenerateButtton, setDisableGenerateButton] = useState(true);

    useEffect(() => {
        setDisableGenerateButton(true);
    }, [open]);


    const handleDateChange = (date) => {
        setDate(date);
        if (date) setDisableGenerateButton(false);
    };

    const [appLink, setAppLink] = useState("");

    const [copyMessage, setCopyMessage] = useState("");

    useEffect(() => {
        setCopyMessage("You're invited to go to " + props.officeName + " for an appointment. To see the details and confirm your appointmment, please sign in to APP . QONSULTA . NET on your phone's Chrome browser and enter this APPOINTMENT CODE: " + appLink.split("/")[3]);
    }, [appLink]);

    const [isClicked, setIsClicked] = useState(false);

    const [disableDatePicker, setDisableDatePicker] = useState(false);

    const [maxAppointments, setMaxAppointments] = useState(defaultSliderValue);

    const [disableSlider, setDisableSlider] = useState(false);

    const handleSliderChange = (event, newValue) => {
        setMaxAppointments(newValue);
    }

    const fetchSchedLink = () => {
        const requestData = {
            "office_id": props.officeId,
            "date": formatDateObjectToString(new Date(date.toString())),
            "max_appointments": maxAppointments
        };
        // console.log("fetchSchedLink", requestData);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/create-schedlink', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify(requestData)
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data", data);
            setAppLink(data.data.link);
            setDisableDatePicker(true);
            setDisableSlider(true);
            if (props.fetchOffices) {
                props.fetchOffices();
            }
        })
        .catch(err => console.error(err));
    }

    return(
        <React.Fragment>
            <Button
                variant='text'
                onClick={handleClickOpen}
                sx={{ mr: 2 }}
            >
                <GroupAddIcon fontSize='medium' sx={{ mr: 1 }} />
                Multiple Users Single Day
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, lineHeight: 1.2 }} id="customized-dialog-title">
                {props.officeName}
                </DialogTitle>
                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
                </IconButton>
                <DialogContent sx={{minWidth: 260}} dividers={true}>
                    <Stack direction="column" justifyContent="center" alignItems='left' spacing={2}>
                        <Typography gutterBottom variant="body1">
                            <b>Multiple Use Code</b><br />
                            Generate a reusable appointment code that you can send out to multiple users.
                        </Typography>
                        <BasicDatePicker disabled={disableDatePicker} handleDateChange={handleDateChange} />
                        <Box sx={{ m: 0, p: 2 }}>
                            <Typography variant="body1">
                                Max Users: <b>{maxAppointments}</b>
                            </Typography>
                            <Slider 
                                defaultValue={defaultSliderValue} 
                                aria-label="Default" 
                                valueLabelDisplay="auto"
                                min={minSliderValue}
                                max={100} 
                                onChange={handleSliderChange}
                                disabled={disableSlider}
                            />
                            {appLink === "" ?

                            <Typography sx={{ }} color="text.secondary" gutterBottom>
                            Warning: Don't set this value to a number beyond what you can serve in a single day.
                            </Typography>
                            : null }
                        </Box>
                        
                        <Stack direction="row" justifyContent="center" alignItems='center' spacing={0}>
                            {appLink !== "" ? <React.Fragment><Typography variant="h4">{appLink.split("/")[3]}</Typography><AppLinkCopyCodeButton appLink={appLink.split("/")[3]}/></React.Fragment> : null}
                        </Stack>
                        
                    </Stack>
                    <Stack direction="row" justifyContent="center" alignItems='center' spacing={2}>
                        {appLink !== "" ? <QrCode code={appLink.split("/")[3]} maxAppointments={maxAppointments} date={formatDateObjectToString(new Date(date.toString()))} officeName={props.officeName} string={appLink}/> : null}
                        {appLink !== "" ? <AppLinkCopyButton appLink={copyMessage}/> : null}
                    </Stack>

                    {/* {appLink !== "" ? <Divider sx={{padding: 1}} /> : null}

                    <Stack direction="column" justifyContent="center" alignItems='center' spacing={2}>
                    {appLink !== "" ? <Typography gutterBottom variant="body1">Share through:</Typography> : null}
                    </Stack>
                        
                    <Stack direction="row" justifyContent="center" alignItems='center' spacing={2}>
                        {appLink !== "" ? <SocialMediaShare appLink={copyMessage} /> : null} 
                    </Stack> */}
                </DialogContent>
                <DialogActions>
                    {appLink === "" ? <Button disabled={isClicked} onClick={handleClose}>Cancel</Button> : null}
                    {appLink === "" ? <Button disabled={isClicked || disableGenerateButtton} autoFocus onClick={onGenerateCodeClick}>Generate Code</Button> : null}
                    {appLink !== "" ? <Button autoFocus onClick={handleClose}>Done</Button> : null}
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default GenerateSchedLink;