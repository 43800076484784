import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { formatDate, formatTime } from '../helpers/Helpers';
import { SERVER_URL } from '../constants';
import CustomerList from './CustomerList';
import EditTodaysSchedule from './EditTodaysSchedule';
import TransportControls from './TransportControls';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AppointmentCodeOptions from './AppointmentCodeOptions';
import MinutesCounter from './MinutesCounter';

function OfficeTodaysSchedule (props) {

    // useEffect(() => {
    //     props.fetchOffices();
    // }, [nowServing]);

    const [scheduleId, setScheduleId] = useState({
        schedule_id: props.schedule.id
    });

    const onNextButtonClick = (event) => {
        serveNextCustomer(false);
    }

    const onNoShowButtonClick = (event) => {
        serveNextCustomer(true);
    }

    const onBackButtonClick = (event) => {
        rollback();
    }

    const onPausePlayButtonClick = (event) => {
        togglePausePlay();
    }

    const [disableTransportControls, setDisableTransportControls] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };


    const serveNextCustomer = (noShow = false) => {
        console.log("serveNextCustomer");
        setDisableTransportControls(true);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/serve-next', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify({
                schedule_id: props.schedule.id,
                no_show: noShow
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            if (data.status == 2)
            {
                console.log(data.message);
                setErrorMessage("You've reached the end of the queue.");
                handleSnackbarOpen();
                props.fetchOffices();
            }
            else{
                props.fetchOffices();
            }
            setDisableTransportControls(false);
        })
        .catch((err) => {
            console.error(err);
            setDisableTransportControls(false);
        });
    }

    const rollback = () => {
        // console.log("rollback");
        setDisableTransportControls(true);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/serve-back', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify(scheduleId)
        })
        .then(response => response.json())
        .then(data => {
            //console.log(data);
            if (data.status == 2)
            {
                console.log(data.message);
                setErrorMessage("You've reached the beginning of the queue.");
                handleSnackbarOpen();
                props.fetchOffices();
            }
            else{
                props.fetchOffices();
            }
            setDisableTransportControls(false);
        })
        .catch((err) => {
            console.error(err);
            setDisableTransportControls(false);
        });
    }

    const togglePausePlay = () => {
        // console.log("togglePausePlay");
        setDisableTransportControls(true);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/toggle-pause-schedule', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify(scheduleId)
        })
        .then(response => response.json())
        .then(data => {
            setDisableTransportControls(false);
            props.fetchOffices();
        })
        .catch((err) => {
            console.error(err);
            setDisableTransportControls(false);
        });
    }

    return(
        <React.Fragment>
            <Box sx={{ p: 1 }}>
                <Stack alignItems="center" justifyContent="center" direction="column" spacing={1}>
                    <Typography gutterBottom variant="subtitle1">
                    {formatDate(props.schedule.date)} | {formatTime(props.schedule.start_time)} - {formatTime(props.schedule.end_time)}
                    </Typography>
                    <Typography gutterBottom variant="body2">
                    { props.schedule.paused ? "PAUSED" : "Now Serving" }
                    </Typography>
                    <Typography gutterBottom variant="h3">
                    { disableTransportControls ? <CircularProgress size="2rem" /> : props.schedule.now_serving ? props.schedule.end_reached ? "..." : props.schedule.now_serving : "..." }
                    </Typography>
                    <Typography gutterBottom variant="h6">
                    {props.schedule.now_serving_name ? props.schedule.now_serving_name : "..."}
                    </Typography>
                    {props.schedule.running_minutes && !props.schedule.paused ? <MinutesCounter startingNumber={props.schedule.running_minutes} /> : null }
                    <Typography gutterBottom variant="body2">
                        {"Service Velocity: " + props.schedule.average_serve_minutes +   " mins / client"}
                    </Typography>
                    <Stack justifyContent="center" alignItems="center" direction="row" spacing={1}>
                        { props.schedule.now_serving === 0 && props.schedule.end_reached === 0 ? 
                            <Button
                            disabled={disableTransportControls}
                            onClick={onNextButtonClick}
                            size="small"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            variant='outlined'
                            >
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={0}
                                    width={70}
                                    >
                                    <PlayArrowIcon fontSize='large' /> Start
                                </Stack>
                            </Button>
                        :
                            <TransportControls 
                            disableAll = {disableTransportControls}
                            onBackButtonClick = {onBackButtonClick}
                            nowServing = {props.schedule.now_serving}
                            endReached = {props.schedule.end_reached}
                            paused = {props.schedule.paused}
                            onPausePlayButtonClick = {onPausePlayButtonClick}
                            onNextButtonClick = {onNextButtonClick}
                            onNoShowButtonClick = {onNoShowButtonClick}
                            />
                        }
                    </Stack>
                    
                </Stack>
                <Stack
                direction="row"
                justifyContent={props.schedule.now_serving === 0 ? "center" : "flex-end"}
                alignItems="center"
                spacing={2}
                >
                    <Typography gutterBottom variant="body1">
                        <b>{props.schedule.num_waiting}</b> in queue
                    </Typography>
                </Stack>
                
            </Box>
            <Box sx={{ p: 1 }}>
                <Stack alignItems="center" justifyContent="center" direction="row" spacing={1}>
                    <AppointmentCodeOptions setRecentAppLinkGenerate={props.setRecentAppLinkGenerate} fetchOffices={props.fetchOffices} officeId={props.officeId} officeName={props.officeName} />
                    <CustomerList fetchOffices={props.fetchOffices} averageServeMinutes={props.schedule.average_serve_minutes} officeId={props.officeId} date={formatDate(props.schedule.date)} rawDate={props.schedule.date} officeName={props.officeName} scheduleId={props.schedule.id}/>
                    <EditTodaysSchedule officeName={props.officeName} scheduleId={props.schedule.id} fetchOffices={props.fetchOffices} date={formatDate(props.schedule.date)} startTime={props.schedule.start_time} endTime={props.schedule.end_time} />
                </Stack>
            </Box>

            <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <Alert
                onClose={handleSnackbarClose}
                severity="info"
                variant="filled"
                sx={{ width: '100%' }}
                >
                {errorMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default OfficeTodaysSchedule;