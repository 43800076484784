import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import addtohomescreen from '../addtohomescreen.gif';
import { isMobileSafari } from 'react-device-detect';


function AddToHomeScreenInstructions (props) {

    useEffect(() => {
        var runningAsApp = window.navigator.standalone;
        var userDeclinedAddToHomeScreen = localStorage.getItem("userDeclinedAddToHomeScreen");
        if (isMobileSafari && !runningAsApp && userDeclinedAddToHomeScreen !== 'yes')
        {
            handleOpen();
        }
    }, []);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDontShow = () => {
        localStorage.setItem("userDeclinedAddToHomeScreen", "yes");
        handleClose();
    }

    return(
        <React.Fragment>
            {props.showButton ? 
            <Button variant='text' onClick={handleOpen}>
                Show Instructions
            </Button>
            : null }
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            style={{
                zIndex: 1302,
            }}
            >
                <DialogContent sx={{ m: 0, p: 4 }}>
                    <Typography sx={{ }} gutterBottom>
                    <b>Add Qonsulta to Your Home Screen to enable all features</b>
                    </Typography>
                    
                    <Typography sx={{ }} gutterBottom>
                        Follow the animated instructions below to add Qonsulta to your iPhone Home Screen.
                    </Typography>
                    <br />
                    <Stack direction="column" justifyContent="center" alignItems='center' sx={{ m: 0, p: 0 }} spacing={2}>
                        <img src={addtohomescreen} alt="" width="100%" height="100%"/>
                    </Stack>   
                </DialogContent>

                <DialogActions>
                    {props.showButton ? null :
                    <Button onClick={handleDontShow}>
                        Don't Show This Again
                    </Button>
                    }
                    <div style={{flex: '1 0 0'}} />
                    <Button onClick={handleClose}>
                        Got it
                    </Button>
                </DialogActions>

            </Dialog>
        </React.Fragment>
    );
}

export default AddToHomeScreenInstructions;