import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

function MinutesCounter (props) {

    const [count, setCount] = useState(0);

    useEffect(() => {
        setCount(props.startingNumber);
    }, [props.startingNumber]);

    return(
        <React.Fragment>
            <Typography variant='body2' gutterBottom>
                {count === 1 ? count + " min running" : count + " mins running"} 
            </Typography>
        </React.Fragment>
    );
}

export default MinutesCounter;