import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SERVER_URL } from '../constants';
import { Stack } from '@mui/material';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { Scanner } from '@yudiel/react-qr-scanner';
import { useNavigate } from 'react-router-dom';
import { MuiFileInput } from 'mui-file-input'
import qrcodeParser from "qrcode-parser";
import Resizer from "react-image-file-resizer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function QrScanner (props) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setText("");
        setFile(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [text, setText] = useState("");
    const [result, setResult] = useState(null);

    const navigate = useNavigate();

    const redirectToAppLink = (code) => {
        navigate('/' + code, {replace: true});
    }

    const handleResult = (text, result) => {
        setText(text);
        setResult(result);
        if (text.includes("app.qonsulta.net"))
        {
            var code = text.split("/")[3];
            setText(code);
            handleClose();
            redirectToAppLink(code);
        }
        else {
            setText("Invalid QR Code");
        }
    }

    const [file, setFile] = React.useState(null);

    const handleChange = async (newFile) => {
        const image = await resizeFile(newFile);
        parseQrCode(image);
    }

    const resizeFile = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
        uri => {
          resolve(uri);
        }, 'base64' );
    });
    
    const parseQrCode = (file) => {
        qrcodeParser(file).then((res) => {
            handleResult(res, res);
        })
        .catch((err) => {
            setText("Error reading uploaded image");
            console.error(err);
        });
    }

    return(
        <React.Fragment>
            <IconButton
                    onClick={handleClickOpen}
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ p: '10px' }} 
                >
                <CropFreeIcon fontSize='medium' />
            </IconButton>
            <BootstrapDialog
                sx={{
                    "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                    },
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                    >
                        <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        Scan or Upload QR Code
                        </Typography>
                        
                    </Stack>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Scanner
                        onResult={handleResult}
                        onError={(error) => alert(error?.message)}
                    />
                    <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        {text} 
                    </Typography>
                    <br />
                    <MuiFileInput 
                        inputProps={{ accept: '.png, .jpeg, .jpg' }} 
                        value={file} 
                        label="Tap below to upload QR Code"
                        onChange={handleChange} 
                    />
                </DialogContent>
                <DialogActions>
                    
                    <Button onClick={handleClose} >Close</Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default QrScanner;