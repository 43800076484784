import React, {useEffect, useState} from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

const today = dayjs();

export default function BasicTimePicker(props) {

    useEffect(() => {
        setValue(dayjs(props.value));
    }, [props.value]);

    const handleChange = (date) => {
        props.setStartTime(date);
    };

    const [value, setValue] = useState(null);

    return (
        <React.Fragment>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker 
                onChange={handleChange} 
                name={props.name}
                label={props.label} 
                value={value}
            />
        </LocalizationProvider>
        </React.Fragment>
    );
}