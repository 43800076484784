import React, {useEffect, useState} from 'react';
import { SERVER_URL } from '../constants';
import List from '@mui/material/List';
import OfficesListItem from './OfficesListItem';
import { REFRESH_SECONDS } from '../constants';

function Offices (props) {

    const [focus, setFocus] = useState(document.hasFocus());
    const useHasFocus = () => {
        // get the initial state

        useEffect(() => {
            // helper functions to update the status
            const onFocus = () => setFocus(true);
            const onBlur = () => setFocus(false);

            // assign the listener
            // update the status on the event
            window.addEventListener("focus", onFocus);
            window.addEventListener("blur", onBlur);

            // remove the listener
            return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
            };
        }, []);

        // return the status
        return focus;
    };

    const FocusDetector = () => {
        const focus = useHasFocus();
        // console.log(focus);
        return <></>;
    };

    useEffect(() => {
        if (focus) fetchOffices();
    }, [focus]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchOffices();
        }, 1000 * REFRESH_SECONDS);
        
        return () => clearInterval(interval);

    }, []);

    const [offices, setOffices] = useState([]);

    useEffect(() => {
        fetchOffices();
    }, []);

    const fetchOffices = () => {
        console.log('fetchOffices()');
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/list-offices', {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === "Unauthenticated.")
            {
                props.logout();
            }
            setOffices(data.data);
        })
        .catch(err => console.error(err));
    }

    return(
        <React.Fragment>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {offices.map(office => (
                <OfficesListItem setRecentAppLinkGenerate={props.setRecentAppLinkGenerate} key={office.id} office={office} fetchOffices={fetchOffices} />
            ))} 
            </List>
            <FocusDetector />
        </React.Fragment>
    );
}

export default Offices;