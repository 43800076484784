import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { GOOGLE_CLIENT_ID, BUILD_PLATFORM, PLATFORM_PWA, PLATFORM_ANDROID, PLATFORM_IOS } from '../constants';
import { FacebookProvider, LoginButton } from 'react-facebook';
import logo192 from '../logo192.png';
import googleLogo from '../g-logo.png';
import Register from './Register';
import ResetPassword from './ResetPassword';
import LoginHelp from './LoginHelp';
import LoginGuest from './LoginGuest';
import Avatar from '@mui/material/Avatar';

function Login(props) {

    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const [disableButton, setButtonDisabled] = useState(false);

    const handleChange = (event) => {
        setUser({...user, [event.target.name] : event.target.value});
        // setButtonDisabled(user.email && user.password ? false : true);
    }

    const handleLoginClick = () => {
        setButtonDisabled(true);
        var success = props.login(user);
        if (!success) setButtonDisabled(false);
    }

    const handleLoginGoogleClick = () => {
        props.loginGoogleAndroid();
    }

    const handleFbLoginSuccess = (response) => {
        console.log(response);
    }

    const handleFbLoginError = (error) => {
        console.log(error);
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleLoginClick();
        }
    }

    return(
        <React.Fragment>
            <Dialog open={true}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            >
                <DialogTitle style={{ background: '#ffbb00', color:'#ffffff' }}>
                <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                >
                    <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0}
                    >
                        <img src={logo192} alt="" width={50} height={50} />
                        onsulta
                        
                    </Stack>
                    <LoginHelp handleLoginGoogleClick={handleLoginGoogleClick} loginGuest={props.loginGuest} loginGoogle={props.loginGoogle} login={props.login}/>
                </Stack>
                
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt={2} alignItems="left" >
                        <Typography variant="body2  ">
                            LOGIN
                        </Typography>
                        <TextField
                            name="email"
                            label="E-mail"
                            onChange={handleChange} />
                        <TextField
                            type="password"
                            name="password"
                            label="Password"
                            onKeyDown={handleKeyPress}
                            onChange={handleChange}/>
                            
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            disabled={(!user.email || !user.password)}
                            onClick={handleLoginClick}
                            >
                            Login
                        </Button>

                        { BUILD_PLATFORM === PLATFORM_PWA ?
                        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                        <GoogleLogin size='large' text='signin_with' type='standard'
                            onSuccess={credentialResponse => {
                                //console.log(jwtDecode(credentialResponse.credential));
                                props.loginGoogle(credentialResponse.credential);
                            }}
                            onError={() => {
                                alert('Login Failed');
                            }}
                        />
                        </GoogleOAuthProvider>
                        : null }
                        
                        { BUILD_PLATFORM === PLATFORM_ANDROID ?
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            disabled={disableButton}
                            onClick={handleLoginGoogleClick}
                            startIcon={<Avatar src={googleLogo} />}
                            >
                            Sign-in with Google
                        </Button>
                        : null }

                        <LoginGuest loginGuest={props.loginGuest} />
                        
                        {/* <FacebookProvider appId="1409165926695569">
                            <LoginButton
                                scope="email, public_profile"
                                onError={handleFbLoginSuccess}
                                onSuccess={handleFbLoginError}
                            >
                                Login via Facebook
                            </LoginButton>
                        </FacebookProvider> */}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <ResetPassword login={props.login} />
                    <div style={{flex: '1 0 0'}} />
                    <Register login={props.login} />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default Login;