import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import CopyButton from './CopyButton';
import chromelogo from '../chromelogo192.png';
import { SERVER_URL } from '../constants';
import DialogActions from '@mui/material/DialogActions';
import TesterDialog from './TesterDialog';

function DevEnvChecker (props) {

    const checkServerUrl = () => {
        if (SERVER_URL === "https://devapi.qonsulta.net/") {
            handleOpen();
        }
    }

    useEffect(() => {
        checkServerUrl();
    }, []);

    const [appUrl, setAppUrl] = useState("https://app.qonsulta.net");

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <React.Fragment>
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            style={{
                zIndex: 1302,
            }}
            >
                <DialogContent sx={{ m: 0, p: 4 }}>
                    <Typography sx={{ }} gutterBottom>
                    <b>Qonsulta Dev Environment</b>
                    </Typography>
                    
                    <Typography sx={{ }} gutterBottom>
                    Oops! This is the Qonsulta dev app used for testing purposes only. If you came here by accident, please copy the link below and paste it on <b>Google Chrome</b>.
                    </Typography>

                    <Stack direction="column" justifyContent="center" alignItems='center' sx={{ m: 0, p: 2 }} spacing={2}>
                        <img src={chromelogo} alt="" height={30} />
                        <Typography sx={{ }}><b>{appUrl}</b></Typography>
                        <CopyButton string={appUrl} />
                    </Stack>

                    
                    
                </DialogContent>

                <DialogActions>
                    <TesterDialog handleClose={handleClose} />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default DevEnvChecker;