import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { SERVER_URL } from '../constants';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDate, formatTime } from '../helpers/Helpers';
import { Stack } from '@mui/material';
import CustomerList from './CustomerList';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ScheduleDelete from './ScheduleDelete';
import ListItemIcon from "@mui/material/ListItemIcon";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    },
}));
  
function ScheduleList (props) {
      
    const [open, setOpen] = React.useState(false);

    const [message, setMessaage] = useState("fetching data...");

    const [pastDates, setPastDates] = useState(0);

    useEffect(() => {
        fetchSchedulesList();
    }, [pastDates]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.fetchOffices();
    };

    const [schedulesList, setSchedulesList] = useState([]);

    const fetchSchedulesList = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/list-office-schedules/' + props.officeId + '/' + pastDates, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data.data", data.data);
            setSchedulesList(data.data);
            if (data.data.length < 1) setMessaage("No schedules");
        })
        .catch(err => console.error(err));
    }

    const togglePastDates = () => {
        pastDates === 0 ? setPastDates(1) : setPastDates(0);
        setSchedulesList([]);
        setMessaage('fetching data...');
        // fetchSchedulesList();
    }

    return(
        <React.Fragment>
            <IconButton
                    onClick={handleClickOpen}
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                <CalendarMonthIcon fontSize='medium' />
            </IconButton>
            <BootstrapDialog
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={0}
                >
                    <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                    {props.officeName}
                    </Typography>
                    <Typography variant="body1">
                    Schedules
                    </Typography>
                </Stack>
            </DialogTitle>
            
            <DialogContent sx={{ m: 0, p: 0 }} dividers>

                <List>
                    {schedulesList.map(schedule => (
                    <ListItem
                        key={schedule.id}
                        secondaryAction={
                            pastDates === 0  ? <ScheduleDelete fetchSchedulesList={fetchSchedulesList} scheduleId={schedule.id} officeName={props.officeName} scheduleDate={formatDate(schedule.date)} edge="end" aria-label="delete" /> : null 
                        }
                    >
                        
                        <ListItemAvatar>
                            <Avatar>
                            <CalendarMonthIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={formatDate(schedule.date)}
                            secondary={formatTime(schedule.start_time) + " - " + formatTime(schedule.end_time)}
                        />
                        <ListItemIcon>
                            <CustomerList fetchOffices={props.fetchOffices} averageServeMinutes={schedule.average_serve_minutes} officeId={props.officeId} hideButton={false} date={formatDate(schedule.date)} rawDate={schedule.date} officeName={props.officeName} scheduleId={schedule.id}/>
                        </ListItemIcon>
                    </ListItem>
                    ))} 
                    {schedulesList.length < 1 ? <Typography  sx={{ ml: 2, flex: 1 }} variant="body2" component="div">{ message }</Typography> : null}
                </List>
                </DialogContent>
                <DialogActions>
                    <FormControlLabel
                    value="start"
                    control={
                        <Switch 
                            checked={ pastDates === 1 ? true : false }
                            color="primary" 
                            onChange={ togglePastDates }
                        />
                    }
                    label="Past Dates"
                    labelPlacement="start"
                    />
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default ScheduleList;