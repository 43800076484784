import React, { useState, useEffect, useRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AlertTitle from '@mui/material/AlertTitle';

function CommonSnackbar(props) {

    useEffect(() => {
        setErrorTitle(props.snackbar.errorTitle);
        setErrorMessage(props.snackbar.errorMessage);
        if (props.snackbar.errorMessage && props.snackbar.errorMessage)
        {
            handleSnackbarOpen();
        }
    }, [props.snackbar.errorTitle, props.snackbar.errorMessage]);

    const [errorTitle, setErrorTitle] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
        props.snackbar.setErrorTitle("");
        props.snackbar.setErrorMessage("");
    };
 
    return (
        <React.Fragment>
            <Snackbar autoHideDuration={4000} anchorOrigin={{ vertical:'top', horizontal:'center' }} open={snackbarOpen} onClose={handleSnackbarClose}>
                <Alert
                    icon={<NotificationsIcon fontSize="inherit" />} 
                    onClose={handleSnackbarClose}
                    severity="info"
                    variant="standard"
                    sx={{ width: '100%', whiteSpace: 'pre-wrap' }}
                >
                    <AlertTitle>{errorTitle}</AlertTitle>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
      );
}

export default CommonSnackbar;