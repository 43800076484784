import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import { SERVER_URL, BUILD_PLATFORM, PLATFORM_PWA, PLATFORM_ANDROID, PLATFORM_IOS } from '../constants';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { appointmentStatusToString, formatDate, appointmentStatusAvatarStyle, appointmentStatusToIcon } from '../helpers/Helpers';
import EditContactNotes from './EditContactNotes';
import ConfirmDialog from './ConfirmDialog';
import { downloadCsv, downloadCsvAndroid } from '../helpers/Helpers';
import QuickModal from './QuickModal';
import CustomerListOptions from './CustomerListOptions';
import ListItemIcon from "@mui/material/ListItemIcon";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

function ContactDetails(props) {

    const [contact, setContact] = useState({
        id: "",
        name: "",
        notes: ""
    });

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        fetchContactDetails();
        fetchAppointmentHistory();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [snackbackOpen, setSnackbackOpen] = React.useState(false);

    const [snackbarSeverity, setSnackbarSeverity] = useState("info");

    const [snackbarMessage, setSnackbarMessage] = useState("test");

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackbackOpen(false);
    };

    const [message, setMessaage] = useState("fetching data...");

    const [history, setHistory] = useState([]);

    const [csvData, setCsvData] = useState([]);

    const convertDataForCsv = (jsonData) => {
        var array = [];
        var header = {date: 'Date', status: 'Status', minutes: 'Minutes'};
        array.push(header);
        for (var item of jsonData) 
        {
            var object = {};
            object.date = item.schedule_date;
            object.status = appointmentStatusToString(item.status);
            object.minutes = item.minutes;
            array.push(object);
        }
        setCsvData(array);
    }

    const handleDownloadCsv = () => {
        var csvFilename = contact.name + " History";
        if (BUILD_PLATFORM === PLATFORM_PWA)
        {
            downloadCsv({data: csvData, fileName: csvFilename});
        }
        if (BUILD_PLATFORM === PLATFORM_ANDROID)
        {
            downloadCsvAndroid({data: csvData, fileName: csvFilename});
            setQuickModalMessage( "CSV file downloaded to your Documents folder: [" + csvFilename + ".csv]");
            setQuickModalOpen(true);
        }
    }

    const [quickModalOpen, setQuickModalOpen] = useState(false);

    const [quickModalMessage, setQuickModalMessage] = useState("");

    const fetchContactDetails = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/detail-contact/' + props.officeId + '/' + props.contactId, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data.data", data.data);
            setContact(data.data);
        })
        .catch(err => console.error(err));
    }

    const fetchAppointmentHistory = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/list-contact-appointment-history/' + props.officeId + '/' + props.userId, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data.data", data.data);
            setHistory(data.data);
            convertDataForCsv(data.data);
            if (data.data.length < 1) setMessaage("No appointment history");
        })
        .catch(err => console.error(err));
    }

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const onDeleteButtonClick = (event) => {
        setConfirmDeleteOpen(true);
    }
    const handleConfirmDelete = () => {
        deleteContact();
        handleClose();
    }

    const deleteContact = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/delete-contact',
        {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify({
                "id" : props.contactId
            })
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data", data);
            if (data.status == 1) {
                props.fetchContacts();
            }
            else {
                
            }
        })
        .catch(err => console.error(err))
    }

    return(
        <React.Fragment>
            <QuickModal 
                quickModalOpen={quickModalOpen}
                quickModalMessage={quickModalMessage}
                setQuickModalOpen={setQuickModalOpen} 
            />
            <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={snackbackOpen} onClose={handleSnackbarClose} autoHideDuration={6000} >
                <Alert
                severity={snackbarSeverity}
                variant="filled"
                sx={{ width: '100%' }}
                >
                { snackbarMessage }
                </Alert>
            </Snackbar>

            <IconButton edge="end" aria-label="add contact"  onClick={handleOpen} >
                <ChevronRightIcon />
            </IconButton>
            <BootstrapDialog
            sx={{
                "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                },
                },
            }}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    
                    {contact.name}
                    
                    <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                    >
                        <Typography style={{whiteSpace: "pre-line", verticalAlign: "bottom"}} color="" variant="body2">
                        {contact.notes === null ? "(no notes)" : contact.notes} 
                        </Typography>
                        
                        <EditContactNotes fetchContactDetails={fetchContactDetails} officeId={props.officeId} contactId={props.contactId} contactNotes={contact.notes} contactName={contact.name} />
                    </Stack>
                </DialogTitle>
                
                <DialogContent sx={{ m: 0, p: 2 }} dividers={true}>
                    <List>
                        {history.map(item => (
                                <ListItem key={item.id}>
                                    <ListItemAvatar>  
                                    <Avatar sx={appointmentStatusAvatarStyle(item.status)}>
                                        {appointmentStatusToIcon(item.status)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={formatDate(item.schedule_date)} secondary={item.minutes? appointmentStatusToString(item.status) + " | " + item.minutes + " mins" : appointmentStatusToString(item.status)} />
                                <ListItemIcon>
                                    <CustomerListOptions disableAddButton={true} minutes={item.minutes} status={item.status} date={item.schedule_date} officeId={props.officeId} appointmentId={item.id} customerId={item.user_id} customerName={contact.name} />
                                </ListItemIcon>
                            </ListItem>
                        ))} 
                        {history.length < 1 ? <Typography  sx={{ ml: 2, flex: 1 }} variant="body2" component="div">{ message }</Typography> : null}
                    </List>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onDeleteButtonClick}>
                        Delete
                    </Button>

                    <Button onClick={handleDownloadCsv}>
                        Export List
                    </Button>
                    
                    <div style={{flex: '1 0 0'}} />
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>

            </BootstrapDialog>

            <ConfirmDialog
                title="Delete Contact"
                open={confirmDeleteOpen}
                setOpen={setConfirmDeleteOpen}
                onConfirm={handleConfirmDelete}
            >
                Are you sure you want to <b>delete {contact.name}</b> from your contacts list ?
            </ConfirmDialog>

        </React.Fragment>
    );
}

export default ContactDetails;