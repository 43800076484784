import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { SERVER_URL, REFRESH_SECONDS } from '../constants';
import List from '@mui/material/List';
import AppointmentsListItem from './AppointmentsListItem';
import ConfirmDialog from './ConfirmDialog';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddAppointment from './AddAppointment';
import { USER_TYPE_CUSTOMER, USER_TYPE_GUEST } from '../constants';

function Appointments2(props) {

    const [focus, setFocus] = useState(document.hasFocus());
    const useHasFocus = () => {
        // get the initial state

        useEffect(() => {
            // helper functions to update the status
            const onFocus = () => setFocus(true);
            const onBlur = () => setFocus(false);

            // assign the listener
            // update the status on the event
            window.addEventListener("focus", onFocus);
            window.addEventListener("blur", onBlur);

            // remove the listener
            return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
            };
        }, []);

        // return the status
        return focus;
    };

    const FocusDetector = () => {
        const focus = useHasFocus();
        // console.log(focus);
        return <></>;
    };
        
    const routeParams = useParams();

    const [appointments, setAppointments] = useState([]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchAppointments();
        }, 1000 * REFRESH_SECONDS);
        
        return () => clearInterval(interval);

    }, []);

    // render list when URL has changed
    useEffect(() => {
        if (focus) fetchAppointments();
    }, [routeParams, focus]);

    const fetchAppointments = () => {
        console.log("fetchAppointments");
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/cust/list-appointments', {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === "Unauthenticated.")
            {
                props.logout();
            }
            setAppointments(data.data);
        })
        .catch((err) => {
            console.error(err);
        });
    }

    const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);

    const [cancelId, setCancelId] = useState(null);

    const [cancelling, setCancelling] = useState(false);

    const onCancelButtonClick = (event) => {
        setConfirmCancelOpen(true);
        setCancelId(event.target.dataset.id);
    }

    const handleConfirmCancel = () => {
        cancelAppointment();
    }

    const cancelAppointment = () => {
        setCancelling(true);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/cust/cancel-appointment/' + cancelId, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(() => {
            if (props.userType == USER_TYPE_GUEST) 
            {
                props.logout();
            }
            else {
                fetchAppointments();
                setCancelling(false);
            }
        })
        .catch((err) => {
            console.error(err);
            setCancelling(false);
        });
    }

    if (appointments)
    {
        return (
            <React.Fragment>
                { props.userType == USER_TYPE_CUSTOMER ? <AddAppointment /> : null }
                <Box sx={{ p: 1 }} >
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {appointments.map(appointment => (
                        <AppointmentsListItem key={appointment.id} appointment={appointment} onCancelButtonClick={onCancelButtonClick} cancelling={cancelling}/>
                    ))} 
                    </List>
                </Box>
                
                <ConfirmDialog
                    title="Cancel Appointment?"
                    open={confirmCancelOpen}
                    setOpen={setConfirmCancelOpen}
                    onConfirm={handleConfirmCancel}
                >
                    Are you sure you want to cancel this appointment? This action cannot be reversed.
                    { props.userType == USER_TYPE_GUEST ? " Continuing will sign you out of the app immediately." : null  }
                </ConfirmDialog>
                <FocusDetector />
            </React.Fragment>
            
        );
    } else
    {
        return (
            <React.Fragment>
                {props.userType == USER_TYPE_CUSTOMER && !props.confirmCode ? <AddAppointment /> : null}
                <Box sx={{ p: 2 }} >
                    <Stack spacing={2} alignItems='center'>
                        <Typography variant="body1" component="div">
                        You have no confirmed appointments.
                        </Typography>
                    </Stack>
                </Box>
            </React.Fragment>
        );
    }
}

export default Appointments2;