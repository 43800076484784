import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { SERVER_URL } from '../constants';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  
function ScheduleDelete (props) {

    const [open, setOpen] = React.useState(false);

    const [deleting, setDeleting] = useState(false);

    const [errorDeleting, setErrorDeleting] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false)
        }
    };   

    const handleDelete = () => {
        deleteSchedule();
    }

    const deleteSchedule= () => {
        setDeleting(true);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/delete-schedule',
        {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify({
                "schedule_id": props.scheduleId
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log("data", data);
            if (data.status == 1) {
                handleClose();
                setDeleting(false);
                props.fetchSchedulesList();
            } else {
                setDeleting(false);
                setErrorDeleting(true);
            }
        })
        .catch((err) => {
            console.error(err);
            setDeleting(false);
        });
    }

    return(
        <React.Fragment>
            {props.hideButton ? 
                null :
                <IconButton
                        onClick={handleClickOpen}
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    >
                    <DeleteIcon fontSize='medium' />
                </IconButton>
            }
            
            <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                    >
                        <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        Delete Schedule?
                        </Typography>
                        <Typography variant="body1">
                        {props.scheduleDate} | {props.officeName}
                        </Typography>
                    </Stack>
                </DialogTitle>
            
                <DialogContent sx={{ m: 0, p: 0 }} dividers>
                    { errorDeleting ? 
                    <Alert severity="error">Error encountered while trying to delete schedule.</Alert>
                    :
                    <Typography variant="body1">
                        Are you sure you want to DELETE this schedule? This action cannot be reversed.
                    </Typography>
                    }
                </DialogContent>
                <DialogActions>
                    { deleting ? <CircularProgress size="2rem" /> : null }
                    <Button disabled={deleting} onClick={handleDelete}><DeleteIcon fontSize='large' />
                        Delete
                    </Button>
                    <Button disabled={deleting} autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default ScheduleDelete;