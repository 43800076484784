import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import { SERVER_URL } from '../constants';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import EditAppointmentNotes from './EditAppointmentNotes';
import { formatDate, appointmentStatusToString, appointmentStatusAvatarStyle, appointmentStatusToIcon } from '../helpers/Helpers';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

function CustomerListOptions(props) {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        fetchNotes();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddContact = () => {
        addContact();
        handleClose();
    };

    const [snackbackOpen, setSnackbackOpen] = React.useState(false);

    const [snackbarSeverity, setSnackbarSeverity] = useState("info");

    const [snackbarMessage, setSnackbarMessage] = useState("test");

    const [notes, setNotes] = useState("");

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackbackOpen(false);
    };

    const fetchNotes= () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/appointment-notes/' + props.officeId + '/' + props.appointmentId,
        {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify()
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data", data);
            if (data.status == 1) {
                setNotes(data.data);
            }
        })
        .catch(err => console.error(err))
    }

    const addContact= () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/create-contact/' + props.officeId,
        {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify({
                "user_id" : props.customerId
            })
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data", data);
            if (data.status == 1) {
                setSnackbarSeverity("info");
                setSnackbarMessage(data.message);
                setSnackbackOpen(true);
            }
            else {
                setSnackbarSeverity("error");
                setSnackbarMessage(data.message);
                setSnackbackOpen(true);
            }
        })
        .catch(err => console.error(err))
    }
    

    return(
        <React.Fragment>

            <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={snackbackOpen} onClose={handleSnackbarClose} autoHideDuration={6000} >
                <Alert
                severity={snackbarSeverity}
                variant="filled"
                sx={{ width: '100%' }}
                >
                { snackbarMessage }
                </Alert>
            </Snackbar>

            <IconButton edge="end" aria-label="add contact"  onClick={handleOpen} >
                <ChevronRightIcon />
            </IconButton>
                <BootstrapDialog
                sx={{
                    "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                    },
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={0}
                        >
                            <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                            {props.customerName}
                            </Typography>
                            <Typography variant="body1">
                            {formatDate(props.date)} | {appointmentStatusToString(props.status)} {props.minutes ? " | " + props.minutes + " mins" : null}
                            </Typography>
                        </Stack>
                    </DialogTitle>
                    
                    <DialogContent sx={{ m: 0, p: 2 }} dividers={true}>
                        <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                        padding={2}
                        >
                            <Typography style={{whiteSpace: "pre-line", verticalAlign: "bottom"}} color="" variant="body2">
                            {!notes ? "(no notes)" : notes} 
                            </Typography>
                            
                            <EditAppointmentNotes status={props.status} date={props.date} officeId={props.officeId} fetchNotes={fetchNotes} appointmentId={props.appointmentId} customerName={props.customerName} customerNotes={notes}/>
                        </Stack>
                    </DialogContent>

                    <DialogActions>
                        {props.disableAddButton ? null :
                        <Button onClick={handleAddContact}>
                            Add to Contacts
                        </Button>
                        }
                        <div style={{flex: '1 0 0'}} />
                        <Button onClick={handleClose}>
                            Close
                        </Button>
                    </DialogActions>

                </BootstrapDialog>
        </React.Fragment>
    );
}

export default CustomerListOptions;