import React, {useEffect, useState} from 'react';
import { ViberShareButton, FacebookMessengerShareButton } from 'react-share';
import { ViberIcon, FacebookMessengerIcon } from 'react-share';
import { FACEBOOK_APP_ID } from '../constants';
import Stack from '@mui/material/Stack';

function SocialMediaShare (props) {

    return(
        <React.Fragment>
            <ViberShareButton url={props.appLink} >
                <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                width={70}
                >
                    <ViberIcon size={32} round /> Viber
                </Stack>
            </ViberShareButton>
            <FacebookMessengerShareButton appId={FACEBOOK_APP_ID} url={props.appLink} >
                <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                width={70}
                >
                    <FacebookMessengerIcon size={32} round /> Messenger
                </Stack>
            </FacebookMessengerShareButton>
        </React.Fragment>
    );
}

export default SocialMediaShare;