import React, {useEffect, useState} from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';


function AppLinkCopyButton (props) {

    useEffect(() => {
        setState({
            value: props.appLink, 
            copied: false
        });
    }, [props.appLink]);

    const [state, setState] = useState({
        value: props.appLink,
        copied: false
    });
    
    const handleCopy = () => {
        setState({
            value: state.value, 
            copied: true
        });
        setSnackbarOpen(true);
    };

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
    };
    

    return(
        <React.Fragment>
            <CopyToClipboard text={state.value} onCopy={handleCopy}>
                <Button><ContentCopyIcon fontSize='medium' />Copy Code with Message</Button>
            </CopyToClipboard>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
                Invite message with code copied to Clipboard!
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default AppLinkCopyButton;