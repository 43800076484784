import React, { useState, useEffect } from 'react';
import ConfirmDialog from './ConfirmDialog';
import { App as CapacitorApp } from '@capacitor/app';

function AndroidBackButtonHandler (props) {

    useEffect(() => {
        CapacitorApp.addListener('backButton', ({canGoBack}) => {
            if(!canGoBack){
                setConfirmExitOpen(true);
            } else {
                window.history.back();
            }
        });
    }, []);

    const [confirmLogoutOpen, setConfirmExitOpen] = useState(false);
    
    const handleConfirmExit = () => {
        CapacitorApp.minimizeApp();
    }

    return(
        <React.Fragment>
            <ConfirmDialog
                title="Leave Qonsulta?"
                open={confirmLogoutOpen}
                setOpen={setConfirmExitOpen}
                onConfirm={handleConfirmExit}
            >
                Click YES to minimize Qonsulta in the background.
            </ConfirmDialog>
        </React.Fragment>
    );
}

export default AndroidBackButtonHandler;