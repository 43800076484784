import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SERVER_URL } from '../constants';
import { Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import { formatDate, appointmentStatusToString, appointmentStatusAvatarStyle, appointmentStatusToIcon } from '../helpers/Helpers';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function EditAppointmentNotes (props) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setNotes(props.customerNotes === null ? "" : props.customerNotes);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const[notes, setNotes] = useState("");

    const handleChange = (event) => {
        setNotes(event.target.value);
    }

    const handleSave = () => {
        updateNotes();
        handleClose();
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            // e.preventDefault();
            // handleSave();
        }
    }

    const updateNotes= () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/update-appointment-notes/' + props.officeId,
        {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify({
                "id" : props.appointmentId,
                "notes" : notes
            })
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data", data);
            if (data.status == 1) {
                props.fetchNotes();
            }
        })
        .catch(err => console.error(err))
    }

    return(
        <React.Fragment>
            <IconButton
                    onClick={handleClickOpen}
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                <EditIcon fontSize='medium' />
            </IconButton>
            <BootstrapDialog
                sx={{
                    "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                    },
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                    >
                        <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        {props.customerName}
                        </Typography>
                        <Typography variant="body1">
                        {formatDate(props.date)} | {appointmentStatusToString(props.status)}
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers={true} sx={{ m: 0, p: 0 }} >
                <TextField
                    sx={{ width: 1 }}
                    name='notes'
                    value={notes}
                    id="outlined-multiline-static"
                    label="Notes"
                    multiline
                    minRows={4}
                    maxRows={8}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onKeyDown={handleKeyPress}
                />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >Cancel</Button>
                    <Button onClick={handleSave} >Save</Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default EditAppointmentNotes;