import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Appointments from './Appointments';
import Settings from './Settings';
import AppLinks from './AppLinks';
import { useParams, useNavigate } from 'react-router-dom';
import { USER_TYPE_CUSTOMER, USER_TYPE_MODERATOR, USER_TYPE_GUEST, PLATFORM_PWA, PLATFORM_ANDROID, PLATFORM_IOS, BUILD_PLATFORM } from '../constants';
import Offices from './Offices';
import Stack from '@mui/material/Stack';
import logo192 from '../logo192.png';
import NotificationsInvite from './NotificationsInvite';
import AddToHomeScreenInstructions from './AddToHomeScreenInstructions';

function Main(props) {

    const routeParams = useParams();

    const [recentAppointmentConfirm, setRecentAppointmentConfirm] = useState(false);
    const [recentAppLinkGenerate, setRecentAppLinkGenerate] = useState(false);

    const navigate = useNavigate();

    const redirectToAppLink = () => {
        navigate('/' + localStorage.getItem("appointment_code"), {replace: true});
    }

    useEffect(() => {
        if (props.guestLoggedIn)
        {
            redirectToAppLink();
        }
    }, [props.guestLoggedIn]);

    return(
        <Box>
            { props.userType != USER_TYPE_MODERATOR ? <NotificationsInvite userType={props.userType} recentAppointmentConfirm={recentAppointmentConfirm} notificationsFailed={props.notificationsFailed} notificationsEnabled={props.notificationsEnabled} requestNotifications={props.requestNotifications} /> : null }
            { props.userType == USER_TYPE_MODERATOR ? <NotificationsInvite userType={props.userType} recentAppLinkGenerate={recentAppLinkGenerate} notificationsFailed={props.notificationsFailed} notificationsEnabled={props.notificationsEnabled} requestNotifications={props.requestNotifications} /> : null }
            <AppBar variant='elevation={0}' style={{ background: '#ffbb00' }} position="sticky">
                <Toolbar>
                    <Stack
                    width={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                >          
                        <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={0}
                        >
                            <img src={logo192} alt="" width={40} height={40} />
                            <Typography variant="h6" component="div">
                                onsulta
                            </Typography>
                        </Stack>         
                        <Settings setFontSize={props.setFontSize} notificationsFailed={props.notificationsFailed} notificationsEnabled={props.notificationsEnabled} requestNotifications={props.requestNotifications} userType={props.userType} logout={props.logout} userName={props.userName} fullName={props.fullName}/>
                </Stack>
                </Toolbar>
            </AppBar>
            <AddToHomeScreenInstructions />
            { props.userType == USER_TYPE_GUEST && routeParams.confirmCode ? <AppLinks setRecentAppointmentConfirm={setRecentAppointmentConfirm} logout={props.logout} userType={props.userType} /> : null }
            { props.userType == USER_TYPE_GUEST ? <Appointments logout={props.logout} userType={props.userType} confirmCode={routeParams.confirmCode} /> : null }
            { props.userType == USER_TYPE_CUSTOMER && routeParams.confirmCode ? <AppLinks setRecentAppointmentConfirm={setRecentAppointmentConfirm} userType={props.userType} /> : null }
            { props.userType == USER_TYPE_CUSTOMER ? <Appointments logout={props.logout} userType={props.userType} confirmCode={routeParams.confirmCode} /> : null }
            { props.userType == USER_TYPE_MODERATOR ? <Offices setRecentAppLinkGenerate={setRecentAppLinkGenerate} logout={props.logout} /> : null }
        </Box>
    );
}

export default Main;