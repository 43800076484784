import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import logo192 from '../logo192.png';
import { SERVER_URL } from '../constants';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DialogContentText from '@mui/material/DialogContentText';
import Avatar from '@mui/material/Avatar';
import DoneIcon from '@mui/icons-material/Done';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function Register(props) {

    const [user, setUser] = useState({
        email: '',
        name: '',
        password: ''
    });

    const [disableCancelButton, setDisableCancelButton] = useState(false); 

    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    const [disableActivateButton, setDisableActivateButton] = useState(false);

    const handleChange = (event) => {
        setUser({...user, [event.target.name] : event.target.value});
    }

    const [agree, setAgree] = useState(false);

    const handleCheckbox = (event) => {
        setAgree(event.target.checked);
    }

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setUser({
            email: '',
            name: '',
            password: ''
        })
        setAgree(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [errorMessage, setErrorMessage] = useState("");

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleRegister = () => {
        register(user);
    };

    const register = (user) => {
        // console.log("user", JSON.stringify(user));
        if (!agree)
        {
            setErrorMessage("You must agree to the terms and conditions to use the app.");
            handleSnackbarOpen();
            return;
        }
        setDisableSubmitButton(true);
        setDisableCancelButton(true);
        fetch(SERVER_URL + 'api/register', {
            method: 'POST',
            headers: { 
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body: JSON.stringify(user)
        })
        .then(response => response.json())
        .then(data => {
            if (data.status == 1)
            {
                setRegistered(true);
            }  
            else {
                setErrorMessage(data.message);
                handleSnackbarOpen();
                setDisableCancelButton(false);
                setDisableSubmitButton(false);
            }
        })
        .catch(err => console.error(err))
    }

    const [registered, setRegistered] = useState(false);

    const [pin, setPin] = useState("");

    const handlePin = (event) => {
        setPin(event.target.value);
    }

    const handleActivate = () => {
        activate(pin);
    };

    const handleActivatedClose = () => {
        setActivated(false);
        setRegistered(false);
        setDisableCancelButton(false);
        handleClose();
    }

    const [activated, setActivated] = useState(false);

    const handleRegisterKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleRegister();
        }
    }

    const handleActivateKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleActivate();
        }
    }

    const activate = (pin) => {
        // console.log("pin", pin);
        // console.log("email", user.email);
        setDisableActivateButton(true);
        fetch(SERVER_URL + 'api/activate', {
            method: 'POST',
            headers: { 
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body: JSON.stringify(
                {
                    email: user.email,
                    activation_pin: pin
                }
            )
        })
        .then(response => response.json())
        .then(data => {
            if (data.status == 1)
            {
                // setActivated(true);
                props.login({
                    email: user.email,
                    password: user.password
                });
            }  
            else {
                setErrorMessage(data.message);
                handleSnackbarOpen();
                setDisableActivateButton(false);
            }
        })
        .catch(err => console.error(err))
    }

    return(
        <React.Fragment>
            <Button onClick={handleOpen}>
                Register
            </Button>
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            >
                <DialogTitle style={{ background: '#ffbb00', color:'#ffffff' }}>
                <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                >
                    <img src={logo192} alt="" width={50} height={50} />
                    onsulta
                </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt={2} alignItems="left" >
                        <Typography variant="body2  ">
                            REGISTER
                        </Typography>
                        { !registered ?
                        <React.Fragment>
                        <TextField
                            name="email"
                            label="Your E-mail"
                            onChange={handleChange}
                         />
                         <TextField
                            name="name"
                            label="Full Name"
                            onChange={handleChange}
                         />
                        <TextField
                            type="password"
                            name="password"
                            label="Password"
                            onChange={handleChange}
                            onKeyDown={handleRegisterKeyPress}
                        />
                        <Typography variant="body1" component="div" onClick={() => openInNewTab("https://qonsulta.net/privacy-policy/")}>
                            <u>Terms and Conditions</u>
                        </Typography>
                        <FormControlLabel  
                            control={<Checkbox onChange={handleCheckbox} />} 
                            label="I agree to the terms and conditions of the app." 
                        />
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            disabled={(user.email && user.name && user.password && agree ? false : true) || disableSubmitButton}
                            onClick={handleRegister}
                            >
                            SUBMIT
                        </Button>
                        </React.Fragment>

                        :
                        
                        <React.Fragment>
                        <Typography variant="body2  ">
                            {"Please check your E-mail inbox or Spam folder at"} <b>{user.email}</b> {"for an Activation PIN. Use it to activate your account by typing the PIN below." }
                        </Typography>
                        <TextField
                            type="number"
                            name="activation_pin"
                            label="Activation PIN"
                            onChange={handlePin}
                            onKeyDown={handleActivateKeyPress}
                        />
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            disabled={!pin || disableActivateButton} 
                            onClick={handleActivate}
                            >
                            ACTIVATE
                        </Button>
                        </React.Fragment>
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button disabled={registered || disableCancelButton} onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
                <Snackbar sx={{ height: "40%" }} anchorOrigin={{ vertical:'top', horizontal:'center' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert
                    onClose={handleSnackbarClose}
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%' }}
                    >
                    {errorMessage}
                    </Alert>
                </Snackbar>
            </Dialog>
            
            <Dialog
            open={activated}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            >
            <DialogTitle id="responsive-dialog-title">
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Avatar sx={{ bgcolor: "#86BBD8" }}><DoneIcon /></Avatar>&nbsp;Activation Successful!
            </Stack>
            
            </DialogTitle>
            <DialogContent>
            <DialogContentText>
                You may now sign-in using your E-mail and Password.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleActivatedClose} autoFocus>
                Login
            </Button>
            </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default Register;