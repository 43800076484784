import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { useIsOnline } from 'react-use-is-online';
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';

function InternetChecker (props) {

    const { isOnline, isOffline, error } = useIsOnline();

    const checkConnection = () => {
        if (isOffline) {
            handleOpen();
        } else {
            handleClose();
        }
    }

    useEffect(() => {
        checkConnection();
    }, [isOffline]);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <React.Fragment>
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            style={{
                zIndex: 1301,
            }}
            >
                <DialogContent sx={{ m: 0, p: 4 }}>
                    <Typography sx={{  }} gutterBottom>
                    <b>Internet Connection Problem</b>
                    </Typography>
                    
                    <Typography sx={{  }} gutterBottom>
                        Internet Connection Problem detected. Please check your mobile data or wifi connection.
                    </Typography>

                    <Stack direction="column" justifyContent="center" alignItems='center' sx={{ m: 0, p: 2 }} spacing={2}>
                        <SignalWifiStatusbarConnectedNoInternet4Icon fontSize='large' />
                    </Stack>
                    
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default InternetChecker;