import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import enablenotification from '../enablenotification.png';
import CircularProgress from '@mui/material/CircularProgress';
import NotificationsIcon from '@mui/icons-material/Notifications';

function GuestWarning (props) {

    useEffect(() => {
        if (props.open === true)
        {
            handleOpen();
        }
        else {
            handleClose();
        }
        
    }, [props.open]);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <React.Fragment>
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            style={{
                zIndex: 1302,
            }}
            >
                <DialogContent sx={{ m: 0, p: 4 }}>
                    <Typography sx={{ }} gutterBottom>
                    <b>About Guest Accounts</b>
                    </Typography>
                    <br />
                    <Typography sx={{ }} gutterBottom>
                    Guest accounts are <b>single-use only</b>. The system will sign you out automatically after you're done with your appointment.
                    <br /><br />
                    Guest users will have a <b>limited set of features</b> while using the app.
                    <br /><br />
                    If you're expecting to receive more appointments through Qonsulta in the future, <b>consider creating a standard Qonsulta account</b> or sign in with your <b>Google profile</b> instead.
                    </Typography>

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>
                        Got it
                    </Button>
                </DialogActions>

            </Dialog>
        </React.Fragment>
    );
}

export default GuestWarning;