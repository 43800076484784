import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import QrCode2Icon from '@mui/icons-material/QrCode2';
// import QRCode from "react-qr-code";
import { QRCode } from 'react-qrcode-logo';
import qrcodelogo from '../qrcodelogo.png';
import qonsultafull from '../qonsultafull.png';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function QrCode (props) {

    const [string, setString] = useState("");

    useEffect(() => {
        setString(props.string);
    }, [string, props.string]);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <React.Fragment>
            <Button onClick={handleClickOpen}><QrCode2Icon fontSize='large' />QR Code</Button>
            <BootstrapDialog
                fullScreen
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    {props.officeName}<br />
                    {props.date ? props.date + " | ": null} {props.maxAppointments} {props.maxAppointments > 0 ? props.maxAppointments > 1 ? "users" : "user" : null}
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{ }} color="text.primary" gutterBottom>
                        https://app.qonsulta.net
                    </Typography>
                    <Typography sx={{ }} color="text.primary" gutterBottom>
                        code : <b>{props.code}</b>
                    </Typography>
                    
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    >
                        <QRCode value={string} logoImage={qrcodelogo} size="280" sx={{width: 1}}/>
                        <img src={qonsultafull} alt="" height={40} />
                    </Stack>

                    
                </DialogContent>
                <DialogActions>
                    <div style={{flex: '1 0 0'}} />
                    <Button autoFocus onClick={handleClose}>Done</Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default QrCode;