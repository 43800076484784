import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import logo192 from '../logo192.png';
import { SERVER_URL } from '../constants';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DialogContentText from '@mui/material/DialogContentText';
import Avatar from '@mui/material/Avatar';
import DoneIcon from '@mui/icons-material/Done';

function ResetPassword(props) {

    const [email, setEmail] = useState("");

    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    const [disableResetButton, setDisableResetButton] = useState(false);

    const [disableCancelButton, setDisableCancelButton] = useState(false); 

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setEmail("");
        setResetCode("");
        setPassword("");
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [errorMessage, setErrorMessage] = useState("");

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleSubmitEmail = () => {
        submitRequest();
    };

    const [validEmail, setValidEmail] = useState(false);

    const [resetCode, setResetCode] = useState("");

    const [password, setPassword] = useState("");

    const handleResetCodeChange = (event) => {
        setResetCode(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleSubmitReset = () => {
        submitReset();
    };

    const handleResetClose = () => {
        setResetSuccess(false);
        setValidEmail(false);
        setDisableCancelButton(false);
        handleClose();
    }

    const [resetSuccess, setResetSuccess] = useState(false);

    const handleResetKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleSubmitReset();
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }

    const handleEmailKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleSubmitEmail();
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }

    const submitRequest = () => {
        // console.log("email", email);
        setDisableSubmitButton(true);
        setDisableCancelButton(true);
        fetch(SERVER_URL + 'api/request-password-reset', {
            method: 'POST',
            headers: { 
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body: JSON.stringify({email: email})
        })
        .then(response => response.json())
        .then(data => {
            if (data.status == 1)
            {
                setValidEmail(true);
                setDisableSubmitButton(true);
            }  
            else {
                setErrorMessage(data.message);
                handleSnackbarOpen();
                setDisableSubmitButton(false);
                setDisableCancelButton(false);
            }
        })
        .catch(err => console.error(err))
    }

    const submitReset = () => {
        // console.log("email", email);
        // console.log("reset_code", resetCode);
        // console.log("password", password);
        setDisableResetButton(true);
        setDisableCancelButton(true);
        fetch(SERVER_URL + 'api/reset-password', {
            method: 'POST',
            headers: { 
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body: JSON.stringify({
                email: email,
                reset_code: resetCode,
                password: password
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.status == 1)
            {
                // setResetSuccess(true);
                // setDisableResetButton(true);
                props.login({
                    email: email,
                    password: password
                });
            }  
            else {
                setErrorMessage(data.message);
                handleSnackbarOpen();
                setDisableResetButton(false);
                setDisableCancelButton(false);
            }
        })
        .catch(err => console.error(err))
    }

    return(
        <React.Fragment>
            <Button onClick={handleOpen}>
                Reset Password
            </Button>
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            >
                <DialogTitle style={{ background: '#ffbb00', color:'#ffffff' }}>
                <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                >
                    <img src={logo192} alt="" width={50} height={50} />
                    onsulta
                </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt={2} alignItems="left" >
                        <Typography variant="body2  ">
                            FORGOT PASSWORD?
                        </Typography>

                        { validEmail ?
                        <React.Fragment>
                        <Typography variant="body2  ">
                        {"Please check your E-mail inbox or Spam folder at"} <b>{email}</b> {"for a PASSWORD RESET CODE. Use it to reset your password by typing the code below and entering your new password." }
                        </Typography>
                        <TextField
                            type="number"
                            name="reset_code"
                            label="Reset Code"
                            onChange={handleResetCodeChange}
                         />
                        <TextField
                            type="password"
                            name="password"
                            label="New Password"
                            onChange={handlePasswordChange}
                            onKeyDown={handleResetKeyPress}
                        />
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            disabled={(!resetCode || !password) || disableResetButton}
                            onClick={handleSubmitReset}
                            >
                            RESET PASSWORD
                        </Button>
                        </React.Fragment>

                        :

                        <React.Fragment>
                        <Typography variant="body2  ">
                            Please enter your E-mail address and click the Submit button.
                        </Typography>
                        <TextField
                            name="email"
                            label="Your E-mail"
                            onChange={handleEmailChange}
                            onKeyDown={handleEmailKeyPress}
                         />
                    
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            disabled={!email || disableSubmitButton}
                            onClick={handleSubmitEmail}
                            >
                            SUBMIT
                        </Button>
                        </React.Fragment>
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disableCancelButton} onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
                <Snackbar sx={{ height: "80%" }} anchorOrigin={{ vertical:'top', horizontal:'center' }} open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert
                    onClose={handleSnackbarClose}
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%' }}
                    >
                    {errorMessage}
                    </Alert>
                </Snackbar>
            </Dialog>

            <Dialog
            open={resetSuccess}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            >
            <DialogTitle id="responsive-dialog-title">
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Avatar sx={{ bgcolor: "#86BBD8" }}><DoneIcon /></Avatar>&nbsp;Password Reset Successful!
            </Stack>
            
            </DialogTitle>
            <DialogContent>
            <DialogContentText>
                You may now sign-in using your E-mail and New Password.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleResetClose} autoFocus>
                Login
            </Button>
            </DialogActions>
            </Dialog>

        </React.Fragment>
    );
}

export default ResetPassword;