import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCrLuuNb76KycfZEnVBxTAxG-ppqNDNRrM",
    authDomain: "qonsulta-appointment.firebaseapp.com",
    projectId: "qonsulta-appointment",
    storageBucket: "qonsulta-appointment.appspot.com",
    messagingSenderId: "194575111919",
    appId: "1:194575111919:web:b3d32588ca2763b2292d1a",
    measurementId: "G-6G3GWPT97L"
};

const app = initializeApp(firebaseConfig);

export default app;