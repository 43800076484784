import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import { SERVER_URL } from '../constants';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import GenerateAppLink from './GenerateAppLink';
import GenerateSchedLink from './GenerateSchedLink';
import GeneratePermaLink from './GeneratePermaLink';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

function AppointmentCodeOptions(props) {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddContact = () => {
        handleClose();
    };

    const [snackbackOpen, setSnackbackOpen] = React.useState(false);

    const [snackbarSeverity, setSnackbarSeverity] = useState("info");

    const [snackbarMessage, setSnackbarMessage] = useState("test");

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackbackOpen(false);
    };
    

    return(
        <React.Fragment>

            <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={snackbackOpen} onClose={handleSnackbarClose} autoHideDuration={6000} >
                <Alert
                severity={snackbarSeverity}
                variant="filled"
                sx={{ width: '100%' }}
                >
                { snackbarMessage }
                </Alert>
            </Snackbar>

            <IconButton edge="end" aria-label="appointment code"  onClick={handleOpen} >
                <QrCode2Icon />
            </IconButton>
                <BootstrapDialog
                sx={{
                    "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                    },
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={0}
                        >
                            <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                            Generate Appointment Code
                            </Typography>
                        </Stack>
                    </DialogTitle>
                    
                    <DialogContent sx={{ m: 0, p: 2 }} dividers={true}>
                        <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{ m: 0, p: 2 }}
                        >
                            <GenerateAppLink setRecentAppLinkGenerate={props.setRecentAppLinkGenerate} fetchOffices={props.fetchOffices} officeId={props.officeId} officeName={props.officeName}/>
                            <GenerateSchedLink setRecentAppLinkGenerate={props.setRecentAppLinkGenerate} fetchOffices={props.fetchOffices} officeId={props.officeId} officeName={props.officeName}/>
                            <GeneratePermaLink setRecentAppLinkGenerate={props.setRecentAppLinkGenerate} fetchOffices={props.fetchOffices} officeId={props.officeId} officeName={props.officeName}/>
                        </Stack>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>
                            Close
                        </Button>
                    </DialogActions>

                </BootstrapDialog>
        </React.Fragment>
    );
}

export default AppointmentCodeOptions;