import React, { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

function SearchBox(props) {

    const [keywords, setKeywords] = useState("");
 
    const clearInput = () => {
        setKeywords("");
        filterData("");
    };

    const filterData = (keywords) => {
        var filteredData = props.dataClone.filter(function(contact){
            var allData = contact.name + " " + contact.notes;
            return allData.toLowerCase().indexOf(keywords) >= 0
        });
        if (keywords)
        {
            // console.log("filtered", filteredData);
            props.callBack(filteredData);
            if (filteredData.length < 1) props.setMessage('no results') ;
        }
        else {
            // console.log("dataClone", props.dataClone);
            props.callBack(props.dataClone);
        }
        
    }

    const handleChange = (event) => {
        setKeywords(event.target.value);
        filterData(event.target.value);
    }
 
    return (
        <React.Fragment>
            <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                    value={keywords}
                    onChange={handleChange}
                    autoFocus
                />
                <IconButton onClick={clearInput} type="button" sx={{ p: '10px' }} aria-label="search">
                    <CancelIcon />
                </IconButton>
                
            </Paper>
        </React.Fragment>
      );
}

export default SearchBox;