import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import logo192 from '../logo192.png';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import GuestWarning from './GuestWarning';
import { useParams, useNavigate } from 'react-router-dom';
import Captcha from './Captcha';

function LoginGuest(props) {

    const routeParams = useParams();

    useEffect(() => {
        setUser({
            full_name: "",
            appointment_code: routeParams.confirmCode,
            agree: false
        });
    }, []);

    const [user, setUser] = useState({
        full_name: '',
        appointment_code: '',
        agree: false
    });

    const [disableButton, setButtonDisabled] = useState(true);

    const [disableCancelButton, setDisableCancelButton] = useState(false); 

    const handleChange = (event) => {
        if (event.target.name === "agree")
        {
            setUser({...user, [event.target.name] : event.target.checked});
        }
        else {
            setUser({...user, [event.target.name] : event.target.value});
        }
    }

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openCaptcha, setOpenCaptcha] = useState(false);

    const handleLogin = () => {
        // console.log("user", user);
        // setOpenCaptcha(true);
        props.loginGuest(user);
    };

    const handleSubmit = () => {
        setOpenCaptcha(true);
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleLogin();
        }
    }


    return(
        <React.Fragment>
            <Button onClick={handleOpen}>
                Login As Guest
            </Button>
            <GuestWarning open={open} />
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            >
                <DialogTitle style={{ background: '#ffbb00', color:'#ffffff' }}>
                <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                >
                    <img src={logo192} alt="" width={50} height={50} />
                    onsulta
                </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt={2} alignItems="left" >
                        <Typography variant="body2">
                            LOGIN AS GUEST
                        </Typography>
                        <Typography variant="body2">
                            Please enter your FULL NAME and the APPOINTMENT CODE provided to you.
                        </Typography>
                        <React.Fragment>
                         <TextField
                            name="full_name"
                            label="Full Name"
                            onChange={handleChange}
                         />
                        <TextField
                            name="appointment_code"
                            label="Enter Appointment Code"
                            defaultValue={routeParams.confirmCode}
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                        />
                        <Typography variant="body1" component="div" onClick={() => openInNewTab("https://qonsulta.net/privacy-policy/")}>
                            <u>Terms and Conditions</u>
                        </Typography>
                        <FormControlLabel  
                            control={<Checkbox onChange={handleChange} />} 
                            name="agree"
                            label="I agree to the terms and conditions of the app." 
                        />
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            disabled={!user.full_name || !user.appointment_code || !user.agree}
                            onClick={handleSubmit}
                            >
                            SUBMIT
                        </Button>
                        </React.Fragment>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disableCancelButton} onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Captcha handleLogin={handleLogin} openCaptcha={openCaptcha} setOpenCaptcha={setOpenCaptcha} />
        </React.Fragment>
    );
}

export default LoginGuest;