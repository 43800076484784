import React, { useState, useEffect, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import QrScanner from './QrScanner';

function AddAppointment(props) {

    const [code, setCode] = useState("");

    const clearInput = () => {
        setCode("");
    };

    const navigate = useNavigate();

    const redirectToAppLink = () => {
        navigate('/' + code, {replace: true});
        clearInput();
    }

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            console.log("Enter Key Pressed");
            redirectToAppLink();
        }
    }
 
    return (
        <React.Fragment>
            <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            >
                <InputBase
                    sx={{ ml: 1, flex: 1, typography: 'h6' }}
                    placeholder="Enter Code Here"
                    inputProps={{ 'aria-label': 'enter appointment code' }}
                    value={code}
                    onChange={(event) => {
                      setCode(event.target.value);
                    }}
                    onKeyDown={handleKeyPress}
                />
                <IconButton onClick={clearInput} type="button" sx={{ p: '10px' }} aria-label="search">
                    <CancelIcon />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton 
                disabled={code.length < 6 ? true : false} 
                onClick={redirectToAppLink} 
                color="default" 
                sx={{ p: '20px' }} 
                aria-label="directions">
                    <AddIcon />
                </IconButton>
                <QrScanner setCode={setCode} redirectToAppLink={redirectToAppLink} />
            </Paper>
        </React.Fragment>
      );
}

export default AddAppointment;