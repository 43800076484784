import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { SERVER_URL } from '../constants';
import { Divider, Stack } from '@mui/material';
import QrCode from './QrCode';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AppLinkCopyButton from './AppLinkCopyButton';
import AppLinkCopyCodeButton from './AppLinkCopyCodeButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import LockIcon from '@mui/icons-material/Lock';
import ConfirmDialog from './ConfirmDialog';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function GeneratePermaLink (props) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setAppLink("");
        setIsClicked(false);
        props.setRecentAppLinkGenerate(true);
    };

    const onGenerateCodeClick = () => {
        if(!isClicked) setIsClicked(true);
        fetchAppLink();
    };

    const [disableGenerateButtton, setDisableGenerateButton] = useState(false);

    useEffect(() => {
        setDisableGenerateButton(false);
        fetchAppLink();
    }, [open]);

    const [appLink, setAppLink] = useState("");

    const [copyMessage, setCopyMessage] = useState("");

    useEffect(() => {
        setCopyMessage("You're invited to go to " + props.officeName + " for an appointment. To see the details and confirm your appointmment, please sign in to APP . QONSULTA . NET on your phone's Chrome browser and enter this APPOINTMENT CODE: " + appLink.split("/")[3]);
    }, [appLink]);

    const [isClicked, setIsClicked] = useState(false);

    const [lockCode, setLockCode] = useState(0);

    const [confirmResetOpen, setConfirmResetOpen] = useState(false);

    const handleConfirmReset = () => {
        resetCode();
    }

    const resetCode = () => {
        const requestData = {
            "office_id": props.officeId
        };
        // console.log("fetchAppLink", requestData);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/refresh-officecode', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify(requestData)
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data", data);
            setAppLink(data.data.link);
            if (props.fetchOffices) {
                props.fetchOffices();
            }
        })
        .catch(err => console.error(err));
    }

    const onResetButtonClick = (event) => {
        setConfirmResetOpen(true);
    }

    const toggleLockCode = () => {
        lockCode === 0 ? setLockCode(1) : setLockCode(0);

        const requestData = {
            "office_id": props.officeId
        };
        // console.log("fetchAppLink", requestData);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/toggle-officecode-lock', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify(requestData)
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data", data);
            if (props.fetchOffices) {
                props.fetchOffices();
            }
        })
        .catch(err => console.error(err));
    }

    const fetchAppLink = () => {
        const requestData = {
            "office_id": props.officeId
        };
        // console.log("fetchAppLink", requestData);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/get-officecode', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify(requestData)
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data", data);
            if (data.data.link.split("/")[3] === "")
            {
                resetCode();
                setLockCode(data.data.lock_code);
            }
            else {
                setAppLink(data.data.link);
                setLockCode(data.data.lock_code);
                if (props.fetchOffices) {
                    props.fetchOffices();
                }
            }
            
        })
        .catch(err => console.error(err));
    }

    return(
        <React.Fragment>
            <Button
                variant='text'
                onClick={handleClickOpen}
                sx={{ mr: 2 }}
            >
                <AllInclusiveIcon fontSize='medium' sx={{ mr: 1 }}/>
                Unli Users Unli Days
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, lineHeight: 1.2 }} id="customized-dialog-title">
                {props.officeName}
                </DialogTitle>
                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
                </IconButton>
                <DialogContent sx={{minWidth: 260}} dividers={true}>
                    <Stack direction="column" justifyContent="center" alignItems='left' spacing={2}>
                        <Typography gutterBottom variant="body1">
                            <b>Unlimited Use Code</b><br />
                            This appointment code can be used by an unlimited number of users on any given day. Appointment date will be automatically set to the current date. 
                        </Typography>
                        {/* <AppLinkTextBox appLink={appLink} /> */}
                        <Stack direction="row" justifyContent="center" alignItems='center' spacing={0}>
                            { lockCode ? <LockIcon /> : null }
                            {appLink !== "" ? <React.Fragment><Typography variant="h4">{appLink.split("/")[3]}</Typography><AppLinkCopyCodeButton appLink={appLink.split("/")[3]}/></React.Fragment> : null}
                        </Stack>
                        
                    </Stack>
                    <Stack direction="row" justifyContent="center" alignItems='center' spacing={2}>
                        {appLink !== "" ? <QrCode unlimited={true} code={appLink.split("/")[3]} officeName={props.officeName} string={appLink}/> : null}
                        {appLink !== "" ? <AppLinkCopyButton appLink={copyMessage}/> : null}
                    </Stack>

                    {appLink !== "" ? <Divider sx={{padding: 1}} /> : null}

                    <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    >
                        <FormControlLabel
                        value="start"
                        control={
                            <Switch 
                            checked={ lockCode === 1 ? true : false }
                                color="primary" 
                                onChange={ toggleLockCode }
                            />
                        }
                        label="Lock"
                        labelPlacement="start"
                        />
                        <Typography gutterBottom variant="body1">
                            Lock code to temporarily prevent users from using or scanning the code.
                        </Typography>
                    </Stack>
                </DialogContent>

                <DialogActions>
                    {appLink !== "" ? <Button onClick={onResetButtonClick}>Reset Code</Button> : null}
                    <div style={{flex: '1 0 0'}} />
                    {appLink !== "" ? <Button autoFocus onClick={handleClose}>Done</Button> : null}
                </DialogActions>
            </BootstrapDialog>

            <ConfirmDialog
                title="Reset Code?"
                open={confirmResetOpen}
                setOpen={setConfirmResetOpen}
                onConfirm={handleConfirmReset}
            >
                Are you sure you want to reset the unlimited use code? <br /><br /><b>Warning:</b> Continuing this action will permanently disable the old code.
            </ConfirmDialog>
        </React.Fragment>
    );
}

export default GeneratePermaLink;