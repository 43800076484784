import React, {useEffect, useState} from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ConfirmDialog from './ConfirmDialog';

function TransportControls (props) {

    const [confirmBackDialogOpen, setConfirmBackDialogOpen] = useState(false);
    const onBackButtonClick = (event) => {
        setConfirmBackDialogOpen(true);
    }
    const handleConfirmBackButtonClick = () => {
        props.onBackButtonClick();
    }

    return(
        <React.Fragment>
            <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            >
                <Button 
                    variant='outlined' 
                    color='warning'
                    onClick={props.onNoShowButtonClick}
                    disabled={ props.paused ? true : false || props.disableAll}
                >
                    No Show
                </Button>
                <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                >
                    <Button
                            onClick={onBackButtonClick}
                            disabled={(props.nowServing == 0 && props.endReached == 0) || props.paused ? true : false || props.disableAll}
                            size="small"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            variant='outlined'
                        >
                        <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                        width={70}
                        >
                            <SkipPreviousIcon fontSize='large' /> Previous
                        </Stack>
                    </Button>
                    <Button
                            disabled={props.disableAll}
                            onClick={props.onPausePlayButtonClick}
                            size="small"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            variant='outlined'
                        >
                        <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                        width={70}
                        >
                            {props.paused ? <PlayArrowIcon fontSize='large' /> : <PauseIcon fontSize='large' />}
                            {props.paused ? "Continue" : "Pause"}
                        </Stack>
                    </Button>
                    <Button
                            onClick={props.onNextButtonClick}
                            disabled={ props.paused ? true : false || props.disableAll}
                            size="small"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            variant='outlined'
                        >
                        <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                        width={70}
                        >
                            <SkipNextIcon fontSize='large' /> Next
                        </Stack>
                    </Button>
                </Stack>
            </Stack>

            <ConfirmDialog
                title="Are you sure?"
                open={confirmBackDialogOpen}
                setOpen={setConfirmBackDialogOpen}
                onConfirm={handleConfirmBackButtonClick}
            >
                Only use the Back button if you pressed the Next button by mistake. 
            </ConfirmDialog>
        </React.Fragment>
    );
}

export default TransportControls;