import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import html2canvas from "html2canvas";
import TextField from '@mui/material/TextField';

function Captcha (props) {

    const [text, setText] = useState("");
    const [displayedText, setDisplayedText] = useState("");
    const previewRef = useRef(null);
    const [src, setSrc] = useState(null);

    const generateImage = () => {
        html2canvas(previewRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const link = document.createElement("a");
            link.href = imgData;
            setSrc(link.href);
            link.remove();
            setDisplayedText("");
        });
    };

    useEffect(() => {
        if (props.openCaptcha)
        {
            var randomString = generateRandomString(8);
            setText(randomString);
            setDisplayedText(randomString);
            setMatched(false);
            setTyped("");
            setTimeout(function(){
                generateImage();
            }, 10);
            handleOpen();
        }
    }, [props.openCaptcha]);

    const handleRefreshImageClick = () => {
        var randomString = generateRandomString(8);
        setText(randomString);
        setDisplayedText(randomString);
        setTyped("");
        setTimeout(function(){
            generateImage();
        }, 10);
    }

    const [typed, setTyped] = useState("");

    const [matched, setMatched] = useState(false);

    const handleTextChange = (event) => {
        setTyped(event.target.value);
        if (event.target.value === text)
        {
            setMatched(true);
        }
        else {
            setMatched(false);
        }
    }

    const generateRandomString = (len) => {
        let ans = '';
        let arr = '23456789abcdwfghjkmnprstuvwxyz';
        for (let i = len; i > 0; i--) {
            ans +=
                arr[(Math.floor(Math.random() * arr.length))];
        }
        return ans;
    }

    const handleProceed = () => {
        props.handleLogin();
        handleClose();
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.setOpenCaptcha(false);
    };

    return(
        <React.Fragment>
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            style={{
                zIndex: 1302,
            }}
            >
                <DialogContent sx={{ m: 0, p: 4 }}>
                    <Typography sx={{ }} gutterBottom>
                    Please type the displayed text to proceed
                    </Typography>
                    <br />
                    <Typography variant='body2' sx={{width: "80px"}} ref={previewRef} gutterBottom>
                    {displayedText}
                    </Typography>
                    <img src={src} alt="" />
                    <br /><br />
                    <TextField
                        sx={{width: 1}}
                        name="captcha"
                        label="Type Text"
                        value={typed}
                        onChange={handleTextChange}
                    />
                    { matched ? <Typography variant='body2' sx={{ }} gutterBottom> Matched! You may now proceed. </Typography> : null }
                </DialogContent>

                <DialogActions>
                    <Button disabled={matched} onClick={handleRefreshImageClick}>
                        Refresh Text
                    </Button>
                    <div style={{flex: '1 0 0'}} />
                    <Button disabled={!matched} onClick={handleProceed}>
                        Proceed
                    </Button>
                </DialogActions>

            </Dialog>
        </React.Fragment>
    );
}

export default Captcha;