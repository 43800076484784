import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { SERVER_URL } from '../constants';
import { Stack } from '@mui/material';
import BasicTimePicker from './BasicTimePicker';
import { dateObjectToTimeString } from '../helpers/Helpers';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { mysqlTimeToTimePickerDate } from '../helpers/Helpers';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function EditTodaysSchedule (props) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [startTime, setStartTime] = useState(new Date());
    
    const [endTime, setEndTime] = useState(new Date());

    const handleSave = () => {
        updateSchedule();
        handleClose();
    }

    const updateSchedule= () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/update-schedule/' + props.scheduleId,
        {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify({
                "start_time": dateObjectToTimeString(new Date(startTime)),
                "end_time": dateObjectToTimeString(new Date(endTime))
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log("data", data);
            if (data.status == 1) {
                props.fetchOffices();
            }
            else {
                
            }
        })
        .catch(err => console.error(err))
    }

    return(
        <React.Fragment>
            <IconButton
                    onClick={handleClickOpen}
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
            <EditCalendarIcon fontSize='medium' />
            </IconButton>
            <BootstrapDialog
                sx={{
                    "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                    },
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                    >
                        <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        {props.officeName}
                        </Typography>
                        <Typography variant="body1">
                        {props.date}
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Stack spacing={2}>

                        <BasicTimePicker 
                            value={mysqlTimeToTimePickerDate(props.startTime)} 
                            setStartTime={setStartTime} 
                            name="default_start_time" 
                            label="Opening Time (visible to clients)" 
                        />
                        <BasicTimePicker 
                            value={mysqlTimeToTimePickerDate(props.endTime)} 
                            setStartTime={setEndTime} 
                            name="default_end_time" 
                            label="Closing Time (visible to clients)" 
                        />

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >Cancel</Button>
                    <Button onClick={handleSave} >Save</Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default EditTodaysSchedule;