import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';;


function QuickModal (props) {

    useEffect(() => {
        setMessage(props.quickModalMessage);
        if (props.quickModalOpen === true)
        {
            handleOpen();
        }
    }, [props.quickModalOpen, props.quickModalMessage]);

    const [message, setMessage] = useState("")

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.setQuickModalOpen(false);
    };

    return(
        <React.Fragment>
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            style={{
                zIndex: 1302,
            }}
            >
                <DialogContent sx={{ m: 0, p: 4 }}>
                    
                    <Typography sx={{ }} gutterBottom>
                        {message}
                    </Typography>
                    
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>
                        Got it
                    </Button>
                </DialogActions>

            </Dialog>
        </React.Fragment>
    );
}

export default QuickModal;