import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { TESTER_PASSWORD } from '../constants';

function TesterDialog(props) {

    const [password, setPassword] = useState("");

    const handleChange = (event) => {
        setPassword(event.target.value);
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        if (password === TESTER_PASSWORD)
        {
            setOpen(false);
            props.handleClose();
        }
    };


    return(
        <React.Fragment>
            <Button onClick={handleOpen}>
                I am a tester
            </Button>
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            style={{
                zIndex: 1303,
            }}
            >
                
                <DialogContent>
                    <Stack spacing={2} mt={2} alignItems="left" >
                        <Typography sx={{ }} gutterBottom>
                        <b>Tester Password</b>
                        </Typography>
                        <Typography variant="body2">
                            Please enter your tester password
                        </Typography>
                        <React.Fragment>
                         <TextField
                            name="password"
                            label="Password"
                            onChange={handleChange}
                         />
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            disabled={false}
                            onClick={handleSubmit}
                            >
                            SUBMIT
                        </Button>
                        </React.Fragment>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default TesterDialog;