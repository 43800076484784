import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { ALLOW_GUEST_LOGIN, BUILD_PLATFORM, PLATFORM_PWA, PLATFORM_ANDROID, PLATFORM_IOS } from '../constants';
import DialogActions from '@mui/material/DialogActions';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from '../constants';
import Stack from '@mui/material/Stack';
import logo192 from '../logo192.png';
import Register from './Register';
import ResetPassword from './ResetPassword';
import LoginGuest from './LoginGuest';
import Avatar from '@mui/material/Avatar';
import googleLogo from '../g-logo.png';

function LoginHelp(props) {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        handleOpen();
    }, []);

    return(
        <React.Fragment>
            <IconButton
                onClick={handleOpen}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 0 }}
            >
                <HelpOutlineIcon fontSize='large' />
            </IconButton>
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            >
                <DialogTitle style={{ background: '#ffbb00', color:'#ffffff' }}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={0}
                        >
                            <img src={logo192} alt="" width={50} height={50} />
                            onsulta
                            
                    </Stack>
                </DialogTitle>

                <DialogContent sx={{ m: 0, p: 2 }}>
                <Card variant='outline' sx={{ m: 0, p: 0 }}>
                        <CardContent>
                            <Typography variant="body1" component="div">
                                Previously registered user?
                            </Typography>
                            <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                            >
                                <Button onClick={handleClose} variant='text'>
                                    Login now
                                </Button>
                            </Stack>
                            <Typography variant="body1" component="div">
                                Forgot your password?
                            </Typography>
                            <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                            >
                                <ResetPassword login={props.login} />
                            </Stack>
                        </CardContent>
                    </Card>

                    <Divider />
                    
                    <Card variant='outline' sx={{ m: 0, p: 0 }}>
                        <CardContent>
                            <Typography variant="body1" component="div">
                                New user?
                            </Typography>
                            <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                            >
                                <Register login={props.login} />
                                <Typography variant="body1" component="div">
                                    OR
                                </Typography>

                                { BUILD_PLATFORM === PLATFORM_PWA ?
                                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                                    <GoogleLogin size='large' text='signin_with' type='standard'
                                        onSuccess={credentialResponse => {
                                            //console.log(jwtDecode(credentialResponse.credential));
                                            props.loginGoogle(credentialResponse.credential);
                                        }}
                                        onError={() => {
                                            alert('Login Failed');
                                        }}
                                    />
                                </GoogleOAuthProvider>
                                : null }

                                { BUILD_PLATFORM === PLATFORM_ANDROID ?
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    onClick={props.handleLoginGoogleClick}
                                    startIcon={<Avatar src={googleLogo} />}
                                    >
                                    Sign-in with Google
                                </Button>
                                : null }

                                { ALLOW_GUEST_LOGIN ?
                                    <React.Fragment>
                                    <Typography variant="body1" component="div">
                                        OR
                                    </Typography>
                                    <LoginGuest loginGuest={props.loginGuest} />
                                    </React.Fragment>
                                :
                                null }
                            </Stack>
                        </CardContent>
                    </Card>

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>
                        Got it
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default LoginHelp;