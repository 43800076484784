import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SERVER_URL } from '../constants';
import { Stack } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import TextField from '@mui/material/TextField';
import BasicTimePicker from './BasicTimePicker';
import { dateObjectToTimeString, formatDateObjectToString } from '../helpers/Helpers';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function EditOffice (props) {

    const [office, setOffice] = useState({
        name: "",
        notes: "",
        default_average_serve_minutes: 0
    });

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        fetchOfficeDetails();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [startTime, setStartTime] = useState(new Date());
    
    const [endTime, setEndTime] = useState(new Date());

    const handleChange = (event) => {
        setOffice({...office, [event.target.name]: event.target.value});
    }

    const handleSave = () => {
        var startDate = dateObjectToTimeString(new Date(startTime));
        office.default_start_time = startDate;
        var endDate = dateObjectToTimeString(new Date(endTime));
        office.default_end_time = endDate;
        // console.log("office", office);
        updateOffice(office);
        handleClose();
    }

    const handleSliderChange = (event, newValue) => {
        setOffice({...office, default_average_serve_minutes: newValue});
    }

    const updateOffice= (office) => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/update-office/' + props.officeId,
        {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify(office)
        })
        .then(response => response.json())
        .then(data => {
            console.log("data", data);
            if (data.status == 1) {
                props.fetchOffices();
            }
            else {
                
            }
        })
        .catch(err => console.error(err))
    }

    const fetchOfficeDetails = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/detail-office/' + props.officeId, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            setOffice(data.data);
            var date = formatDateObjectToString(new Date());
            // console.log("date", date + "T" + data.data.default_start_time);
            setStartTime(date + "T" + data.data.default_start_time);
            setEndTime(date + "T" + data.data.default_end_time);
        
        })
        .catch(err => console.error(err));
    }

    return(
        <React.Fragment>
            <IconButton
                    onClick={handleClickOpen}
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
            <EditNoteIcon fontSize='medium' />
            </IconButton>
            <BootstrapDialog
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                      },
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                    >
                        <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                        {office.name}
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Stack spacing={2}>
                        <TextField
                            name='notes'
                            value={office.notes === null ? "" : office.notes}
                            id="outlined-multiline-static"
                            label="Status (visible to clients)"
                            multiline
                            inputProps={{ maxLength: 200 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                            />
                        <BasicTimePicker 
                            value={startTime} 
                            setStartTime={setStartTime} 
                            name="default_start_time" 
                            label="Opening Time (visible to clients)" 
                        />
                        <BasicTimePicker 
                            value={endTime} 
                            setStartTime={setEndTime} 
                            name="default_end_time" 
                            label="Closing Time (visible to clients)" 
                        />

                        <TextField
                            type='number'
                            name='default_average_serve_minutes'
                            value={office.default_average_serve_minutes}
                            id="outlined-multiline-static"
                            label="Default Service Velocity in Minutes"
                            InputProps={{ maxLength: 3 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                            />

                        <Box sx={{ m: 0, p: 1 }}>
                            <Slider 
                                    value={office.default_average_serve_minutes} 
                                    aria-label="Default" 
                                    valueLabelDisplay="auto"
                                    min={5}
                                    max={120} 
                                    onChange={handleSliderChange}
                                    disabled={false}
                                />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >Cancel</Button>
                    <Button onClick={handleSave} >Save</Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default EditOffice;