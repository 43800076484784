import React, { useState, useEffect, useRef } from 'react';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ListItemButton } from '@mui/material';
import { formatDate } from '../helpers/Helpers';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import CampaignIcon from '@mui/icons-material/Campaign';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { getEstimatedTime } from '../helpers/Helpers';
import Badge from '@mui/material/Badge';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));  

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));
  

function AppointmentsListItem(props) {

    const [estTime, setEstTime] = useState("");

    // est time update timer
    useEffect(() => {
        updateEstTime();
        const interval = setInterval(() => {
            updateEstTime();
        }, 60000 * 10); //every 10 mins
        
        return () => clearInterval(interval);

    }, []);

    useEffect(() => {
        updateEstTime();
    }, [props.appointment.now_serving]);

    const updateEstTime = () => {
        console.log("updating est time...");
        setEstTime(getEstimatedTime(props.appointment.date, props.appointment.start_time, props.appointment.average_serve_minutes, props.appointment.now_serving, props.appointment.customer_number, props.appointment.num_cancelled))
    };

    const [date, setDate] = useState(formatDate(props.appointment.date));

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway' || reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };
 
    return (
        <React.Fragment>
            <ListItemButton onClick={handleClickOpen} alignItems="flex-start">
                <ListItemAvatar>
                    { props.appointment.now_serving == props.appointment.customer_number ?
                    <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                        <Avatar style={{ background: '#86BBD8' }}>
                            {date == "TODAY" ? <ScheduleIcon /> : <CalendarMonthIcon /> }
                        </Avatar>  
                    </StyledBadge>
                    :
                    <Avatar style={{ background: '#86BBD8' }}>
                        {date == "TODAY" ? <ScheduleIcon /> : <CalendarMonthIcon /> }
                    </Avatar>  
                    }
                </ListItemAvatar>
                <ListItemText style={{whiteSpace: "pre-line", verticalAlign: "bottom"}}
                    primary={props.appointment.office_name}
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                                alignItems='center'
                            >
                            {date}
                            </Typography>
                            { date == "TODAY" ? <React.Fragment><br /><b>Now serving {props.appointment.now_serving}. You are {props.appointment.customer_number}.</b></React.Fragment> : null } { props.appointment.paused ? <React.Fragment> <b>(paused)</b> </React.Fragment> : null }
                            { date == "TODAY" ? <React.Fragment><br /><b>Est. time: {estTime}</b></React.Fragment> : null }
                            { date == "TODAY" && props.appointment.office_notes ? <React.Fragment><br />{props.appointment.office_notes}</React.Fragment> : null }
                        </React.Fragment>
                    }
                >
                </ListItemText>
            </ListItemButton>
            <Divider variant="middle" component="li" />


            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                      },
                    },
                }}
            >
                <DialogTitle style={{ background: '#86bbd8' }} sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Typography variant='h6' style={{whiteSpace: "pre-line", verticalAlign: "bottom"}} sx={{ lineHeight: 1.2 }} component="div">
                        {props.appointment.office_name + ""}
                    </Typography>
                    <Typography style={{whiteSpace: "pre-line", verticalAlign: "bottom"}} sx={{ lineHeight: 1.2 }} component="div">
                        <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        padding={1}
                        >
                            <AccessTimeFilledIcon />
                            Opens {props.appointment.start_time}<br />Closes {props.appointment.end_time}
                        </Stack>
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <Typography style={{whiteSpace: "pre-line", verticalAlign: "bottom"}} sx={{ lineHeight: 1.2 }} component="div">
                        {props.appointment.office_notes ?
                        <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        padding={1}
                        >
                            <CampaignIcon /> {props.appointment.office_notes}
                        </Stack>
                        : null }
                    </Typography>
                    {props.appointment.office_notes ? <Divider variant="middle" /> : null}
                    <Stack alignItems='center'>
                        <Typography variant='h5' color="text.primary" gutterBottom>
                        {date}
                        </Typography>
                        <Typography sx={{ }} color="text.secondary" gutterBottom>
                        Now serving
                        </Typography>
                        { props.appointment.paused ? <Chip label={"currently paused"} color='info'/> : null }
                        <Typography variant="h4" component="div">
                        {props.appointment.now_serving}
                        </Typography>
                        <Typography sx={{ }} color="text.secondary" gutterBottom>
                        You are
                        </Typography>
                        <Typography variant="h4" component="div">
                        {props.appointment.customer_number}
                        </Typography>
                        <Typography sx={{ }} color="text.secondary" gutterBottom>
                        Est. time
                        </Typography>
                        <Typography variant="h6" component="div">
                        {estTime}
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    { props.cancelling ? <CircularProgress size="2rem" /> : null }
                    <Button disabled={ props.appointment.now_serving === props.appointment.customer_number || props.cancelling ? true : false } onClick={props.onCancelButtonClick} data-id={props.appointment.id} >
                        Cancel Appointment
                    </Button>
                    <div style={{flex: '1 0 0'}} />
                    <Button disabled={props.cancelling} autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
      );
}

export default AppointmentsListItem;