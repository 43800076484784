import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { APP_VERSION, USER_TYPE_GUEST, BUILD_PLATFORM, PLATFORM_PWA } from '../constants';
import ConfirmDialog from './ConfirmDialog';
import CircularProgress from '@mui/material/CircularProgress';
import AddToHomeScreenInstructions from './AddToHomeScreenInstructions';
import { isMobileSafari } from 'react-device-detect';
import Slider from '@mui/material/Slider';

function Settings(props) {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [requestingNotifications, setRequestingNotifications] = useState(false); 

    const enableNotifications = () => {
        props.requestNotifications();
        setRequestingNotifications(true);
    };

    useEffect(() => {
        if (props.notificationsEnabled === true)
        {
            setRequestingNotifications(false);
        }
        if (props.notificationsFailed === true)
        {
            setRequestingNotifications(false);
        }
    }, [props.notificationsEnabled, props.notificationsFailed]);

    const [confirmLogoutOpen, setConfirmLogoutOpen] = useState(false);
    const onLogoutButtonClick = (event) => {
        setConfirmLogoutOpen(true);
    }
    const handleConfirmLogout = () => {
        props.logout();
    }

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    const [fontSize, setFontSize] = useState(localStorage.getItem("fontSize") ? parseInt(localStorage.getItem("fontSize")) : 14);
    
    const handleFontSizeChange = (event, newValue) => {
        setFontSize(newValue);
    }

    const handleApplyFontSize = () => {
        props.setFontSize(fontSize);
    }

    const fontSliderMarks = [
        {
            value: 12,
            label: 'S',
        },
        {
            value: 14,
            label: 'M',
        },
        {
            value: 16,
            label: 'L',
        },
        {
            value: 18,
            label: 'XL',
        },
      ];
      

    return(
        <React.Fragment>
            <IconButton
                onClick={handleOpen}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 0 }}
            >
                <AccountCircleIcon />
            </IconButton>
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            >
                <DialogTitle sx={{m: 0, p: 2}}>
                        Settings
                    <IconButton
                        onClick={handleClose}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ m: 0, p: 2 }}>

                    { BUILD_PLATFORM === PLATFORM_PWA ?    
                    <React.Fragment>
                    { isMobileSafari && !(window.navigator.standalone) ? 
                    <React.Fragment>
                        <Card variant='outline' sx={{ m: 0, p: 0 }}>
                            <CardContent>
                                <Typography sx={{ }} color="text.secondary" gutterBottom>
                                    Add Qonsulta to Home Screen
                                </Typography>      
                                <AddToHomeScreenInstructions showButton={true} />
                            </CardContent>
                        </Card>
                    <Divider />
                    </React.Fragment >
                    : null }
                    <Card variant='outline' sx={{ m: 0, p: 0 }}>
                        <CardContent>
                            <Typography sx={{ }} color="text.secondary" gutterBottom>
                            Notifications
                            </Typography>                  
                            <Typography variant="body1" component="div">
                                {props.notificationsEnabled ? "Enabled" : <Button onClick={enableNotifications} size="medium">{requestingNotifications ? <CircularProgress size="1rem" /> : <NotificationsIcon /> } Enable Notifications</Button> }
                            </Typography>
                            { props.notificationsFailed ? 
                            <Typography variant="body1" component="div">
                                Notifications not supported on this device
                            </Typography>
                            : null }
                        </CardContent>
                    </Card>

                    </React.Fragment>
                    : 
                    <Card variant='outline' sx={{ m: 0, p: 0 }}>
                        <CardContent>
                            <Typography sx={{ }} color="text.secondary" gutterBottom>
                            Notifications
                            </Typography>                  
                            <Typography variant="body1" component="div">
                                {props.notificationsEnabled ? "Enabled" : <Button onClick={enableNotifications} size="medium">{requestingNotifications ? <CircularProgress size="1rem" /> : <NotificationsIcon /> } Enable Notifications</Button> }
                            </Typography>
                            { props.notificationsFailed ? 
                            <Typography variant="body1" component="div">
                                Notifications not supported on this device
                            </Typography>
                            : null }
                        </CardContent>
                    </Card>
                    }

                    <Card variant='outline' sx={{ m: 0, p: 0 }}>
                        <CardContent>
                            <Typography sx={{ }} color="text.secondary" gutterBottom>
                            App Version
                            </Typography>
                            <Typography variant="body1" component="div">
                            {APP_VERSION}
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card variant='outline' sx={{ m: 0, p: 0 }}>
                        <CardContent>
                            <Typography sx={{ }} color="text.secondary" gutterBottom>
                            Visit our website
                            </Typography>
                            <Typography variant="body1" component="div" onClick={() => openInNewTab("https://qonsulta.net")}>
                            https://qonsulta.net
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card variant='outline' sx={{ m: 0, p: 0 }}>
                        <CardContent>
                            <Typography sx={{ }} color="text.secondary" gutterBottom>
                            Adjust Font Size
                            </Typography>
                            <Slider
                                aria-label="FontSize"
                                value={fontSize}
                                valueLabelDisplay="off"
                                onChange={handleFontSizeChange}
                                step={1}
                                min={12}
                                max={18}
                                marks={fontSliderMarks}
                            />
                            <Button variant='outlined' onClick={handleApplyFontSize}>
                                Apply
                            </Button>
                        </CardContent>
                    </Card>

                    <Card variant='outline' sx={{ m: 0, p: 0 }}>
                        <CardContent>
                            <Typography sx={{ }} color="text.secondary" gutterBottom>
                            Account
                            </Typography>
                            <Typography variant="body1" component="div">
                            {props.fullName}<br />
                            {props.userName}
                            </Typography>
                        </CardContent>

                        <CardActions>
                            <Button onClick={onLogoutButtonClick} size="small">Logout</Button>
                        </CardActions>

                    </Card>
                </DialogContent>
            </Dialog>

            <ConfirmDialog
                title="Logout"
                open={confirmLogoutOpen}
                setOpen={setConfirmLogoutOpen}
                onConfirm={handleConfirmLogout}
            >
                {
                    props.userType == USER_TYPE_GUEST ? 
                    "You are signed-in as a GUEST. If you continue, you will be signed-out permanently with no way to sign back in. Are you sure you want to sign-out?" :
                    "Are you sure you want to sign-out?"
                }
            </ConfirmDialog>
        </React.Fragment>
    );
}

export default Settings;