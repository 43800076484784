import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { SERVER_URL, BUILD_PLATFORM, PLATFORM_PWA, PLATFORM_ANDROID, PLATFORM_IOS } from '../constants';
import { Divider, Stack } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ListItemIcon from "@mui/material/ListItemIcon";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PersonIcon from '@mui/icons-material/Person';
import ContactDetails from './ContactDetails';
import SearchBox from './SearchBox';
import { downloadCsv, downloadCsvAndroid } from '../helpers/Helpers';
import QuickModal from './QuickModal';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));
  
function ConstactList (props) {

    const [open, setOpen] = React.useState(false);

    const [message, setMessaage] = useState("fetching data...");

    const handleClickOpen = () => {
        setOpen(true);
        fetchContacts();
    };

    const handleClose = () => {
        setOpen(false);
    };   

    const [contacts, setContacts] = useState([]);

    const [contactsClone, setContactsClone] = useState([]);

    const [csvData, setCsvData] = useState([]);

    const convertDataForCsv = (jsonData) => {
        var array = [];
        var header = {name: 'Name'};
        array.push(header);
        for (var item of jsonData) 
        {
            var object = {};
            object.name = item.name;
            array.push(object);
        }
        setCsvData(array);
    }

    const handleDownloadCsv = () => {
        var csvFilename = props.officeName + " Contacts List";
        if (BUILD_PLATFORM === PLATFORM_PWA)
        {
            downloadCsv({data: csvData, fileName: csvFilename});
        }
        if (BUILD_PLATFORM === PLATFORM_ANDROID)
        {
            downloadCsvAndroid({data: csvData, fileName: csvFilename});
            setQuickModalMessage( "CSV file downloaded to your Documents folder: [" + csvFilename + ".csv]");
            setQuickModalOpen(true);
        }
    }

    const [quickModalOpen, setQuickModalOpen] = useState(false);

    const [quickModalMessage, setQuickModalMessage] = useState("");
    
    const fetchContacts = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/list-contacts/' + props.officeId, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            //console.log("data.data", data.data);
            setContacts(data.data);
            convertDataForCsv(data.data);
            setContactsClone(data.data.slice(0));
            if (data.data.length < 1) setMessaage("No contacts");
        })
        .catch(err => console.error(err));
    }

    return(
        <React.Fragment>
            <QuickModal 
                quickModalOpen={quickModalOpen}
                quickModalMessage={quickModalMessage}
                setQuickModalOpen={setQuickModalOpen} 
            />
            {props.hideButton ? 
                null :
                <IconButton
                        onClick={handleClickOpen}
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                    <ImportContactsIcon fontSize='medium' />
                </IconButton>
            }
            
            <BootstrapDialog
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={0}
                >
                    <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                    {props.officeName}
                    </Typography>
                    <Typography variant="body1">
                    Contacts
                    </Typography>
                </Stack>
                <Divider sx={{ m: 0, p: 1 }} />

                <SearchBox setMessage={setMessaage} dataClone={contactsClone} data={contacts} callBack={setContacts} />

            </DialogTitle>
            
            <DialogContent sx={{ m: 0, p: 0, minHeight: "300px" }} dividers>
                <List>
                    {contacts.map(contact => (
                        <ListItem key={contact.id}>
                            <ListItemAvatar>  
                                <Avatar>
                                    <PersonIcon fontSize='medium' />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={contact.name} />
                            <ListItemIcon>
                                <ContactDetails fetchContacts={fetchContacts} contactId={contact.id} officeId={props.officeId} userId={contact.user_id} />
                            </ListItemIcon>
                        </ListItem>
                    ))} 
                    {contacts.length < 1 ? <Typography  sx={{ ml: 2, flex: 1 }} variant="body2" component="div">{ message }</Typography> : null}
                </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDownloadCsv}>
                        Export List
                    </Button>
                    <div style={{flex: '1 0 0'}} />
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default ConstactList;