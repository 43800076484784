import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { CardHeader, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { SERVER_URL, USER_TYPE_GUEST } from '../constants';
import { REFRESH_SECONDS } from '../constants';
import ConfirmDialog from './ConfirmDialog';
import { useParams, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getEstimatedTime } from '../helpers/Helpers';
import { formatDate } from '../helpers/Helpers';

function AppLinks(props) {

    const routeParams = useParams();

    const [appLink, setAppLink] = useState(null);

    const [error, setError] = useState(false);

    const [message, setMessage] = useState("");

    const [confirmCode, setConfirmCode] = useState({
        confirmation_code: routeParams.confirmCode
    });

    const navigate = useNavigate();


    useEffect(() => {
        fetchAppLink();
        // const interval = setInterval(() => {
        //     fetchAppLink();
        // }, 1000 * REFRESH_SECONDS);
        
        // return () => clearInterval(interval);

    }, []);

    const fetchAppLink = () => {
        console.log("fetchAppLink");
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/cust/detail-applink/' + routeParams.confirmCode, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.data)
            {
                if (data.message === "Unauthenticated.")
                {
                    props.logout();
                }
                setAppLink(data.data);
                setConfirmCode({confirmation_code: data.data.confirmation_code});
            } else {
                if (data.status == 1)
                {
                    setMessage(data.message);
                    setError(true);
                    setOpen(true);
                } else{
                    redirectToHome();
                }
            }
            
        })
        .catch(err => {console.error(err)});
    }

    const confirmAppointment = () => {
        // console.log("confirmCode", confirmCode);
        // return;
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/cust/confirm-applink', {
            method: 'POST',
            headers: { 
                'Content-Type':'application/json',
                'Accept':'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify(confirmCode)
        })
        .then(response => response.json())
        .then(data => {
            props.setRecentAppointmentConfirm(true);
            redirectToHome();
        })
        .catch(err => console.error(err))
    }

    const [confirmDiscardOpen, setConfirmDiscardOpen] = useState(false);

    const onDiscardButtonClick = (event) => {
        setConfirmDiscardOpen(true);
    }

    const handleConfirmDiscard = () => {
        if (props.userType == USER_TYPE_GUEST)
        {
            props.logout();
        }
        else {
            redirectToHome();
        }
    }

    const redirectToHome = () => {
        navigate('/', {replace: true});
    }

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
        redirectToHome();
    };


    if (routeParams)
    {
        if (error)
        {
            return (
                <React.Fragment>
                    <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={60000} onClose={handleClose}>
                        <Alert
                        onClose={handleClose}
                        severity="info"
                        variant="filled"
                        sx={{ width: '100%' }}
                        >
                        { message }
                        </Alert>
                    </Snackbar>
                </React.Fragment>
            );
        }
        else if (appLink)
        {
            return(
                <React.Fragment>
                    <Box sx={{ p: 2 }} >
                        <Stack alignItems='center' spacing={2}>
                            <Card variant='outlined' sx={{ width: 1, borderRadius: '6px', boxShadow: 20 }}>
                                <CardHeader
                                    style={{ background: '#86bbd8' }}
                                    titleTypographyProps={{variant:'h6' }}
                                    title={appLink.office_name}
                                    subheaderTypographyProps={{ }}
                                    subheader={"Opens " + appLink.start_time + ", Closes " + appLink.end_time}                            
                                >
                                </CardHeader>
                                <CardContent>
                                    <Stack spacing={1}>
                                        

                                        <Stack alignItems='center'>
                                            <Typography variant='h4' color="text.primary" gutterBottom>
                                            {formatDate(appLink.date)}
                                            </Typography>
                                            {formatDate(appLink.date) == 'TODAY' ?
                                                <Typography sx={{ }} color="text.secondary" gutterBottom>
                                                Now serving
                                                </Typography>
                                            : null}
                                            { appLink.paused ? <Chip label={"currently paused"} /> : null }
                                            {formatDate(appLink.date) == 'TODAY' ?
                                                <Typography variant="h3" component="div">
                                                {appLink.now_serving}
                                                </Typography>
                                            : null}
                                            <Typography sx={{ }} color="text.secondary" gutterBottom>
                                            You are
                                            </Typography>
                                            <Typography variant="h3" component="div">
                                            { appLink.last_issued_number + 1 }
                                            </Typography>
                                            <Typography sx={{ }} color="text.secondary" gutterBottom>
                                            Est. time
                                            </Typography>
                                            <Typography variant="h5" component="div">
                                            {getEstimatedTime(appLink.date, appLink.start_time, appLink.average_serve_minutes, appLink.now_serving, appLink.last_issued_number + 1, appLink.num_cancelled)}
                                            </Typography>
                                            <Stack sx={{ width: '100%' }} spacing={2}>
                                                <Alert severity="info">Please confirm this appointment by clicking the button below.</Alert>
                                                <Button 
                                                style={{
                                                    
                                                }}
                                                variant="outlined" 
                                                onClick={confirmAppointment} 
                                                data-id={appLink.confirmCode} 
                                                size="medium">
                                                    <CheckCircleOutlineIcon /> Confirm Appointment
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                                <Divider />
                                <CardActions>
                                    <Button size="small" onClick={onDiscardButtonClick}>Discard</Button>
                                </CardActions>
                            </Card>
                        </Stack>
                    </Box>
                    <ConfirmDialog
                        title="Discard Appointment?"
                        open={confirmDiscardOpen}
                        setOpen={setConfirmDiscardOpen}
                        onConfirm={handleConfirmDiscard}
                    >
                        Are you sure you want to discard this appointment?
                        { props.userType == USER_TYPE_GUEST ? " Since you are signed-in as GUEST, this cannot be undone and continuing will sign you out of the app immediately." : null  }
                    </ConfirmDialog>
                </React.Fragment>
            );
        }
    }
}

export default AppLinks;