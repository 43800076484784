import React, { useState, useEffect, useRef } from 'react';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { ListItemButton } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Stack } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ScheduleList from './ScheduleList';
import EditOffice from './EditOffice';
import StorefrontIcon from '@mui/icons-material/Storefront';
import OfficeTodaysSchedule from './OfficeTodaysSchedule';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatTime } from '../helpers/Helpers';
import ConstactList from './ConstactList';
import AppointmentCodeOptions from './AppointmentCodeOptions';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));  

function OfficesListItem(props) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway' || reason === 'backdropClick') {
            return;
        }
        setOpen(false);
        props.fetchOffices();
    };

    useEffect(() => {
        if (props.office.todays_schedule)
        {
            setExpand(false);
        }
        else {
            setExpand(true);
        }
    }, []);

    const [expand, setExpand] = useState(false);

    const toggleExpand = () => {
        if (expand === false)
        {
            setExpand(true);
        }
        else {
            setExpand(false);
        }
        
    };
 
    return (
        <React.Fragment>
            <ListItemButton onClick={handleClickOpen} alignItems={props.office.todays_schedule ? "flex-start" : "center"}>
                <ListItemAvatar>
                    <Avatar style={{ background: '#86BBD8' }}>
                        <StorefrontIcon />
                    </Avatar>  
                </ListItemAvatar>
                <ListItemText style={{whiteSpace: "pre-line", verticalAlign: "bottom"}}
                    primary={props.office.name}
                    secondary={
                        props.office.todays_schedule ?
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                                alignItems='center'
                            >
                            Now serving: <b>{props.office.todays_schedule.end_reached || props.office.todays_schedule.now_serving == 0 ? "..." : props.office.todays_schedule.now_serving}</b> | In queue: <b>{props.office.todays_schedule.num_waiting}</b>
                            </Typography>
                        </React.Fragment>
                        :
                        null
                    }
                >
                </ListItemText>
            </ListItemButton>
            <Divider variant="middle" component="li" />


            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                      },
                    },
                }}
            >
                <DialogTitle style={{ background: '#86bbd8' }} sx={{ m: 0, p: 0 }} id="customized-dialog-title">
                    
                    <Accordion expanded={expand}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        onClick={toggleExpand}
                        sx={{ lineHeight: 1.2 }}
                        >
                        {props.office.name}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{whiteSpace: "pre-line", verticalAlign: "bottom"}} sx={{ lineHeight: 1.2 }} component="div">
                                <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0}
                                padding={1}
                                >
                                    <AccessTimeFilledIcon />
                                    Opens {formatTime(props.office.default_start_time)}<br />Closes {formatTime(props.office.default_end_time)}
                                </Stack>
                            </Typography>
                            
                            <Typography style={{whiteSpace: "pre-line", verticalAlign: "bottom"}} sx={{ lineHeight: 1.2 }} component="div">
                                {props.office.notes ?
                                <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={0}
                                padding={1}
                                >
                                    <CampaignIcon /> {props.office.notes}
                                </Stack>
                                : null }
                            </Typography>
                        </AccordionDetails>
                        <Stack justifyContent="center" direction="row" spacing={1}>
                            <AppointmentCodeOptions setRecentAppLinkGenerate={props.setRecentAppLinkGenerate} fetchOffices={props.fetchOffices} officeId={props.office.id} officeName={props.office.name} />
                            <ScheduleList fetchOffices={props.fetchOffices} officeId={props.office.id} officeName={props.office.name}/>
                            <ConstactList officeId={props.office.id} officeName={props.office.name}/>
                            <EditOffice officeNotes={props.office.notes} officeMinutes={props.office.default_average_serve_minutes} startTime={props.office.default_start_time} endTime={props.office.default_end_time} fetchOffices={props.fetchOffices} officeId={props.office.id} />
                        </Stack>
                    </Accordion>
                </DialogTitle>

                <DialogContent dividers={props.office.todays_schedule ? true : false}>
                    { props.office.todays_schedule ? <OfficeTodaysSchedule setRecentAppLinkGenerate={props.setRecentAppLinkGenerate} fetchOffices={props.fetchOffices}  officeId={props.office.id} officeName={props.office.name} schedule={props.office.todays_schedule} /> : null }
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
      );
}

export default OfficesListItem;