import React, {useEffect, useState} from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';

const today = dayjs();

export default function BasicDatePicker(props) {

  //const [date, setDate] = useState(new Date());

  // const handleChange = (date) => {
  //   props.handleDateChange(date);
  // };

  const [selectedDate, setSelectedDate] = useState(null);

  const onClose = () => {
    console.log('selectedDate', selectedDate);
  }

  const onAccept = () => {
    // console.log('selectedDate', selectedDate);
    props.handleDateChange(selectedDate);
  }

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker  
            // disabled = {props.disabled}
            value={selectedDate}
            onChange={d => setSelectedDate(d)}
            // onClose={onClose}
            onAccept={onAccept}
            disablePast 
            label="Select Date" 
            format="LL" 
            sx={{ width: '100%' }} 
          />
      </LocalizationProvider>
    </React.Fragment>
  );
}