import React, { useState, useEffect, useRef } from 'react';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { appointmentStatusToString, appointmentStatusAvatarStyle, appointmentStatusToIcon } from '../helpers/Helpers';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { SERVER_URL } from '../constants';
import CommonSnackbar from './CommonSnackbar';
import ConfirmDialog from './ConfirmDialog';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import BasicTimePicker from './BasicTimePicker';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

function AppointmentStatusAvatar(props) {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setConfirmAction("");
        setStartTime("");
        setEndTime("");
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [errorTitle, setErrorTitle] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const [confirmAction, setConfirmAction] = useState("");
    const [actionLabel, setActionLabel] = useState(null);

    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const handleOverride = (event) => {
        // console.log("startTime", dayjs(startTime).format('hh:mm:ss'));
        // console.log("endTime", dayjs(endTime).format('hh:mm:ss'));
        setConfirmOpen(true);
    }

    const snackbar = {
        errorTitle,
        errorMessage,
        setErrorTitle,
        setErrorMessage
    }

    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleChange = (event) => {
        // console.log("event.target.value", event.target.value);
        setConfirmAction(event.target.value);
        if (event.target.value === "active") setActionLabel("Confirmed");
        if (event.target.value === "cancelled") setActionLabel("Cancelled");
        if (event.target.value === "now-serving") setActionLabel("Now Serving");
        if (event.target.value === "no-show") setActionLabel("No Show");
        if (event.target.value === "done") setActionLabel("Done");
    }

    const handleConfirm = () => {
        setStatus(confirmAction);
        handleClose();
        setConfirmOpen(false);
    }

    const checkRequiredFields = () => {
        if (confirmAction === 'done')
        {
            if (!startTime || !endTime) {
                return true;
            } else {
                return false;
            }
        }
        else {
            if (!confirmAction) {
                return true;
            } else {
                return false;
            }
        }
    }

    const setStatus= (status) => {
        // console.log("props.customerAppointment.id", props.customerAppointment.id);
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/override-appointment-set-' + status,
        {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            },
            body: JSON.stringify({
                "appointment_id" : props.customerAppointment.id,
                "from": confirmAction === 'done' ? dayjs(`${props.rawDate}T${dayjs(startTime).format('hh:mm:ss')}`).toISOString() : "",
                "to": confirmAction === 'done' ? dayjs(`${props.rawDate}T${dayjs(endTime).format('hh:mm:ss')}`).toISOString() : ""
            })
        })
        .then(response => response.json())
        .then(data => {
            // console.log("data", data);
            if (data.status == 1) {
                console.log("message", data.message);
                props.fetchCustomerAppointments();
                props.fetchOffices();
                setErrorTitle("Message");
                setErrorMessage(data.message);
            }
            else {
                console.log("error", data.message);
                setErrorTitle("Error");
                setErrorMessage(data.message);
            }
        })
        .catch(err => console.error(err))
    }
 
    return (
        <React.Fragment>
            <CommonSnackbar snackbar={snackbar} />
            <ListItemAvatar>
                <Badge anchorOrigin={{vertical: 'top', horizontal: 'left',}} badgeContent={props.customerAppointment.customer_number} color="info" overlap="circular">
                    <Avatar onClick={handleOpen} sx={appointmentStatusAvatarStyle(props.customerAppointment.status)}>
                        {appointmentStatusToIcon(props.customerAppointment.status)}
                    </Avatar>
                </Badge>
            </ListItemAvatar>

            <BootstrapDialog
                sx={{
                    "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                    },
                    },
                }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={0}
                        >
                            <Typography sx={{ lineHeight: 1.2 }} variant="body1">
                                Override Status
                            </Typography>
                            <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                                {props.customerAppointment.name}
                            </Typography>
                            <Typography sx={{ lineHeight: 1.2 }} variant="body1">
                                {appointmentStatusToString(props.customerAppointment.status)}
                            </Typography>
                        </Stack>
                    </DialogTitle>
                    
                    <DialogContent sx={{ m: 0, p: 2 }} dividers={true}>
                        <Stack spacing={2} padding={2}>
                            <Typography sx={{ lineHeight: 1.2 }} variant="body2">
                                Use this function for making corrections only. Depending on certain conditions, some status changes may not be allowed.
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select New Status</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={confirmAction}
                                label="Select New Status"
                                onChange={handleChange}
                                >
                                    <MenuItem value={"active"}>{"Confirmed"}</MenuItem>
                                    <MenuItem value={"cancelled"}>{"Cancelled"}</MenuItem>
                                    {/* <MenuItem value={"now-serving"}>{"Now Serving"}</MenuItem> */}
                                    <MenuItem value={"no-show"}>{"No Show"}</MenuItem>
                                    <MenuItem value={"done"}>{"Done"}</MenuItem>
                                </Select>
                            </FormControl>

                            {(confirmAction === 'done') ? 
                            <React.Fragment>
                                <Typography sx={{ lineHeight: 1.2 }} variant="body2">
                                    Please provide the time the client was served:
                                </Typography>
                                <BasicTimePicker 
                                    value={startTime} 
                                    setStartTime={setStartTime} 
                                    name="from" 
                                    label="Started Serving Client At" 
                                />
                                <BasicTimePicker 
                                    value={endTime} 
                                    setStartTime={setEndTime} 
                                    name="to" 
                                    label="Ended Serving Client At" 
                                />
                            </React.Fragment>
                            : null }
                        </Stack>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleOverride} 
                            disabled={checkRequiredFields()}
                        >
                            Save
                        </Button>
                    </DialogActions>

                </BootstrapDialog>
                
                <ConfirmDialog
                    title={"Set status to " + actionLabel }
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={handleConfirm}
                >
                    Are you sure?
                </ConfirmDialog>
        </React.Fragment>
      );
}

export default AppointmentStatusAvatar;