import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import enablenotification from '../enablenotification.png';
import enablenotification_mod from '../enablenotification_mod.png';
import CircularProgress from '@mui/material/CircularProgress';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { USER_TYPE_MODERATOR } from '../constants';

function NotificationsInvite (props) {

    const [requestingNotifications, setRequestingNotifications] = useState(false); 

    const enableNotifications = () => {
        props.requestNotifications();
        setRequestingNotifications(true);
    };

    useEffect(() => {
        if (props.recentAppointmentConfirm === true || props.recentAppLinkGenerate === true)
        {
            if (localStorage.getItem("notificationsEnabled") === null)
            {
                if ('Notification' in window) {
                    handleOpen();
                }
            }
        }
        if (props.notificationsEnabled === true)
        {
            setRequestingNotifications(false);
        }
        if (props.notificationsFailed === true)
        {
            setRequestingNotifications(false);
        }
    }, [props.notificationsEnabled, props.notificationsFailed, props.recentAppointmentConfirm, props.recentAppLinkGenerate]);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <React.Fragment>
            <Dialog open={open}
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            style={{
                zIndex: 1302,
            }}
            >
                <DialogContent sx={{ m: 0, p: 4 }}>
                    <Typography sx={{ }} gutterBottom>
                    <b>Enable Notifications?</b>
                    </Typography>
                    
                    <Typography sx={{ }} gutterBottom>
                    { props.userType == USER_TYPE_MODERATOR ?
                        "Notifications will keep you updated in real-time on the status of your clients when they confirm or cancel their appointments."
                    :
                        "Notifications will keep you updated on the status of your appointments while the app is running in the background."
                    }
                    </Typography>

                    <Stack direction="column" justifyContent="center" alignItems='center' sx={{ m: 0, p: 2 }} spacing={2}>
                        { props.userType == USER_TYPE_MODERATOR ?
                            <img src={enablenotification_mod} alt="" height={180} />
                        :
                            <img src={enablenotification} alt="" height={180} />
                        }
                        <Typography variant="body1" component="div">
                            {props.notificationsEnabled ? "Enabled" : <Button variant='outlined' onClick={enableNotifications} size="medium">{requestingNotifications ? <CircularProgress size="1rem" /> : <NotificationsIcon /> } Enable Notifications</Button> }
                        </Typography>
                    </Stack>    
                    { props.notificationsFailed ? 
                    <Typography variant="body1" component="div">
                        <b>Notifications not supported on this device</b>
                    </Typography>
                    : null }             
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
        </React.Fragment>
    );
}

export default NotificationsInvite;