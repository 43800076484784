import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PeopleIcon from '@mui/icons-material/People';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { SERVER_URL, BUILD_PLATFORM, PLATFORM_PWA, PLATFORM_ANDROID, PLATFORM_IOS } from '../constants';
import { appointmentStatusToString, appointmentStatusAvatarStyle, appointmentStatusToIcon } from '../helpers/Helpers';
import { Stack } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ListItemIcon from "@mui/material/ListItemIcon";
import CustomerListOptions from './CustomerListOptions';
import { downloadCsv, downloadCsvAndroid } from '../helpers/Helpers';
import QuickModal from './QuickModal';
import AppointmentStatusAvatar from './AppointmentStatusAvatar';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));
  
  
function CustomerList (props) {

    const [open, setOpen] = React.useState(false);

    const [message, setMessaage] = useState("fetching data...");

    const handleClickOpen = () => {
        setOpen(true);
        fetchCustomerAppointments();
    };

    const handleClose = () => {
        setOpen(false);
    };   

    const [customerAppointments, setCustomerAppointments] = useState([]);

    const [csvData, setCsvData] = useState([]);

    const convertDataForCsv = (jsonData) => {
        var array = [];
        var header = {number: 'Number', name: 'Name', status: 'Status', minutes: 'Minutes'};
        array.push(header);
        for (var item of jsonData) 
        {
            var object = {};
            object.number = item.customer_number;
            object.name = item.name;
            object.status = appointmentStatusToString(item.status);
            object.minutes = item.minutes;
            array.push(object);
        }
        setCsvData(array);
    }

    const handleDownloadCsv = () => {
        var csvFilename = props.officeName + " Clients List " + props.rawDate;
        if (BUILD_PLATFORM === PLATFORM_PWA)
        {
            downloadCsv({data: csvData, fileName: csvFilename});
        }
        if (BUILD_PLATFORM === PLATFORM_ANDROID)
        {
            downloadCsvAndroid({data: csvData, fileName: csvFilename});
            setQuickModalMessage( "CSV file downloaded to your Documents folder: [" + csvFilename + ".csv]");
            setQuickModalOpen(true);
        }
    }

    const [quickModalOpen, setQuickModalOpen] = useState(false);

    const [quickModalMessage, setQuickModalMessage] = useState("");
    
    const fetchCustomerAppointments = () => {
        const token = sessionStorage.getItem("token");
        fetch(SERVER_URL + 'api/mod/list-customer-appointments/' + props.scheduleId, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            //console.log("data.data", data.data);
            setCustomerAppointments(data.data);
            convertDataForCsv(data.data);
            if (data.data.length < 1) setMessaage("No confirmed appointments");
        })
        .catch(err => console.error(err));
    }

    return(
        <React.Fragment>
            <QuickModal 
                quickModalOpen={quickModalOpen}
                quickModalMessage={quickModalMessage}
                setQuickModalOpen={setQuickModalOpen} 
            />
            {props.hideButton ? 
                null :
                <IconButton
                        onClick={handleClickOpen}
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                    <PeopleIcon fontSize='medium' />
                </IconButton>
            }
            
            <BootstrapDialog
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                  },
                },
            }}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={0}
                >
                    <Typography sx={{ lineHeight: 1.2 }} variant="h6">
                    {props.officeName}
                    </Typography>
                    <Typography sx={{ lineHeight: 1.2 }} variant="body1">
                    {props.date} Appointments <br />
                    {"Service Velocity: " + props.averageServeMinutes + " mins"}
                    </Typography>
                </Stack>
            </DialogTitle>
            
            <DialogContent sx={{ m: 0, p: 0 }} dividers>
                
                <List>
                    {customerAppointments.map(customerAppointment => (
                        <ListItem key={customerAppointment.id} selected={customerAppointment.status == 3 ? true : false}>
                            <AppointmentStatusAvatar rawDate={props.rawDate} fetchCustomerAppointments={fetchCustomerAppointments} fetchOffices={props.fetchOffices} customerAppointment={customerAppointment} />
                            <ListItemText primary={customerAppointment.name} secondary={customerAppointment.minutes ? appointmentStatusToString(customerAppointment.status) + " | " + customerAppointment.minutes + " mins" : appointmentStatusToString(customerAppointment.status)} />
                            <ListItemIcon>
                                <CustomerListOptions minutes={customerAppointment.minutes} status={customerAppointment.status} date={props.rawDate} officeId={props.officeId} appointmentId={customerAppointment.id} customerId={customerAppointment.user_id} customerName={customerAppointment.name} />
                            </ListItemIcon>
                        </ListItem>
                    ))} 
                    {customerAppointments.length < 1 ? <Typography  sx={{ ml: 2, flex: 1 }} variant="body2" component="div">{ message }</Typography> : null}
                </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDownloadCsv}>
                        Export List
                    </Button>
                    <div style={{flex: '1 0 0'}} />
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default CustomerList;